import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image } from '@react-pdf/renderer';

const logoImg = process.env.PUBLIC_URL + '/bases images/LN1.png';

/*Font.register({
  family: 'Arial',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/arial.ttf',
},{
  family: 'Roboto-Bold',
  src: './fonts/Roboto-Bold.ttf',
});*/
Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
}, {
  family: 'Arial',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/Arial.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  footer: {
    position: 'absolute',
    bottom: 15,
    left: 30,
    right: 0,
    textAlign: 'center',
  },
  footerNumber: {
    position: 'absolute',
    bottom: 15,
    left: 0,
    right: 30,
    textAlign: 'right',
  },
  footerText: {
    fontSize: 9,
    textAlign: 'left',
  },
  pageNumber: {
    fontSize: 12,
  },
  backgroundImageLogo: {
    position: 'absolute',
    left: 40,
    top: 0,
    width: '30%', // Ocupa todo el ancho del encabezado
    height: '140%', // Ocupa todo el alto del encabezado
    opacity: 0.9, // Ajusta la opacidad de la imagen según desees
  },
  paragraphContainer: {
    left: 420,
    top: 0,
    position: 'absolute',
    width: '20%', // Ajusta el ancho del párrafo a la izquierda
    textAlign: 'right',
  },

  //tarjeta superior de rojo y azul

  infoCardContainer: {
    fontSize: 12,
    left: 380,
    top: 85,
    position: 'absolute',
    width: '34%', // Ajusta el ancho del párrafo a la izquierda
    textAlign: 'left',
  },

  infoBlue: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    fontWeight: 1200,
    color: '#063970',
    textAlign: 'left',
  },

  infoRed: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    fontWeight: 1200,
    color: '#FD1C12',
    textAlign: 'right',
  },

  paragraph: {
    fontSize: 10,
    marginBottom: 10,
    lineHeight: 1.2, // Espacio entre líneas
    alignItems: 'right',
  },

  //texto normal para carta
  paragraphCard: {
    fontSize: 9,
    marginBottom: 10,
    lineHeight: 1.2, // Espacio entre líneas
    alignItems: 'right',
  },
  //texto normal para carta con negrilla, usar dentro de otro cardtext
  paragraphCardBold: {
    fontFamily: 'Open Sans', fontSize: 9, fontWeight: 800,
  },
  titleContainer: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: 9,
    fontWeight: 800,
    textAlign: 'center',
    marginBottom: 10,
  },
  content: {
    fontSize: 12,
  },

  //tablas
  table: {
    fontSize: 10,
    width: '100%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 0,
    paddingBottom: 0,
  },
  bold: {
    fontFamily: 'Open Sans', fontSize: 9, fontWeight: 1200,
  },
  // So Declarative and unDRY 👌
  col1: {
    width: '50%',
    border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#ededed',
    padding: '1px', // Añade espacio interno
  },
  col2: {
    textAlign: 'center',
    width: '50%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  colhalf1: {
    width: '12%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  colhalf2: {
    width: '12%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  colhalf3: {
    width: '12%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  colhalf4: {
    width: '12%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  //tablas azules
  tableBlue: {
    fontSize: 10,
    width: '100%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },
  rowBlueTitle: {
    display: 'flex',
    //flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#28a5b2',
    color: '#fff',
    alignItems: 'center',
  },
  rowBlue: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#28a5b2',
    color: '#fff',
    alignItems: 'center',
  },
  textrowBlue: {
    color: '#fff',
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontSize: 11,
    fontWeight: 800,
  },

  //top row blue

  col1HeaderBlue: {
    width: '10%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },
  col2HeaderBlue: {
    width: '50%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },
  col3HeaderBlue: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },
  col4HeaderBlue: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },
  col5HeaderBlue: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },

  //CONTENIDO TABLA BLANCO
  tableWhite: {
    fontSize: 10,
    width: '100%',
    border: '1px solid #daedf7', // Define el borde
    padding: '1px', // Añade espacio interno
  },

  rowWhite: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#FFF',
    color: '#000',
    alignItems: 'center',
  },

  rowWhiteNoBorder: {
    display: 'flex',
    flexDirection: 'row',
    //borderTop: '1px solid #EEE',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: '#FFF',
    color: '#000',
    alignItems: 'center',
  },

  col1White: {
    width: '10%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
  },
  colTypeWhite: {
    width: '20%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },
  col2White: {
    width: '30%',
    border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    fontSize: 8,
    color: '#000',
    padding: '1px', // Añade espacio interno
  },

  col3White: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
  },
  col4White: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },
  col5White: {
    width: '13%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },
  col4WhiteBorder: {
    width: '13%',
    border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },
  col5WhiteBorder: {
    width: '13%',
    border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },
  colEmptyWhite: {
    width: '74%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
  },

  // COLUMNAS ULTIMAS TABLAS
  col1HeaderBlueLast: {
    width: '35%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },

  col2HeaderBlueLast: {
    width: '20%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },

  col3HeaderBlueLast: {
    width: '35%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },

  col4HeaderBlueLast: {
    width: '10%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#28a5b2',
    color: '#fff',
    padding: '1px', // Añade espacio interno
  },

  col1WhiteLast: {
    width: '35%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
    fontSize: 8,
  },

  col2WhiteLast: {
    width: '20%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
    fontSize: 8,
  },

  col3WhiteLast: {
    width: '35%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
    fontSize: 8,
  },

  col4WhiteLast: {
    width: '10%',
    //border: '1px solid #daedf7', // Define el borde
    backgroundColor: '#FFF',
    color: '#000',
    padding: '1px', // Añade espacio interno
    textAlign: 'center',
    fontSize: 8,
  },

});



const PdfGenerator = ({ equipos, entidadSeleccinada, IVA, Consumibles, TOTAL, currentDate, endDate, UserGenerator }) => {
  const MAX_TABLE_HEIGHT = 450; // Altura máxima permitida para la tabla en una página
  let numEqui = equipos.length;
  const divideEquipos = (equipos) => {
    const pages = [];
    let currentPage = [];
    let currentPageHeight = 0;
    let pageIndex = 1;


    equipos.forEach((equipo) => {
      const equipoHeight = 70; // Altura estimada de cada fila del equipo (ajusta según sea necesario)

      // Verifica si la fila actual cabe en la página actual
      if (currentPageHeight + equipoHeight <= MAX_TABLE_HEIGHT) {
        currentPage.push(equipo);
        currentPageHeight += equipoHeight;
      } else {
        // Si la fila no cabe, crea una nueva página
        //pages.push(currentPage);
        pages.push({ title: `Página ${pageIndex}`, equipos: currentPage });
        currentPage = [equipo];
        currentPageHeight = equipoHeight;
      }
    });

    if (currentPage.length > 0) {
      //pages.push(currentPage);
      pages.push({ title: `Página ${pageIndex}`, equipos: currentPage });
    }

    return pages;
  };

  const pages = divideEquipos(equipos);


  return (
    <PDFViewer width="100%" height="500px">
      <Document>
        {pages.map((page, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page} >
            <View style={styles.section}>
              <View style={styles.header}>
                <Image src={logoImg} style={styles.backgroundImageLogo} />
                <View style={styles.titleContainer}>
                  <Text style={[styles.title, styles.bold]}>
                    FORMATO COTIZACION{"\n"}
                    SERVICIOS DE CALIBRACION</Text>
                </View>
                <View style={styles.paragraphContainer}>
                  <Text style={styles.paragraph}>
                    <Text style={styles.bold}>
                      Identificacion:
                      {"\n"}
                      FC-014
                      {"\n"}
                      Version:0
                      {"\n"}
                      Fecha de aprobacion:
                      {"\n"}
                      {currentDate}
                    </Text>
                  </Text>
                </View>
              </View>
              <View style={styles.infoCardContainer}>
                <Text><Text style={styles.infoBlue}>COTIZACION No.                     </Text><Text style={styles.infoRed}> BIOC 144-2023</Text></Text>
                <Text><Text style={styles.bold}>Valido hasta                      </Text><Text style={styles.bold}>{endDate}</Text></Text>
              </View>
              <Text style={styles.paragraphCard}>
                {"\n"}
                {"\n"}
                Bucaramanga                 {currentDate}
                {"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                Señores
                {"\n"}
                <Text style={styles.bold}>
                  {entidadSeleccinada.nombrecomercial}
                </Text>
                {"\n"}
                Marsiol vargas
                {"\n"}
                NIT: 860.005.114
                {"\n"}
                {entidadSeleccinada.city}
                {"\n"}
                {"\n"}
                Cordial Saludo:
                {"\n"}
                En respuesta a su solicitud de cotización, me permito presentar nuestra propuesta de servicio.
                {"\n"}
                BIOASESORES S.A.S asegura la calidad de sus resultados, con un sistema basado en el cumplimiento de los requisitos establecidos en la NTC- ISO/ IEC 17025.
                {"\n"}
                En caso de resultar favorecidos y con el propósito de satisfacer sus necesidades de manera ágil, oportuna y con calidad, respetuosamente solicitamos
                asegurarse de que los datos consignados en la presente oferta sean los apropiados.<Text style={styles.paragraphCardBold}> Solicitamos amablemente leer cuidadosamente las condiciones del
                  servicio que a continuación se describen y comunicar cualquier duda antes de ser firmada o aceptada. Nosotros estamos prestos a resolver cualquier
                  inquietud que resulte de la misma.</Text>
              </Text>

              {/* Agrega más contenido aquí */}
              {/* Agregar tabla */}
              <View style={styles.table}>
                <View style={[styles.row, styles.bold,]}>
                  <Text style={styles.col1}>Lugar ejecucion del servicio</Text>
                  <Text style={styles.col2}>En citio</Text>
                </View>
                <View style={[styles.row, styles.bold,]}>
                  <Text style={styles.col1}>Ciudad: </Text>
                  <Text style={styles.col2}>Bogota</Text>
                </View>
                <View style={[styles.row, styles.bold,]}>
                  <Text style={styles.col1}>Formato de Certificado:  </Text>
                  <Text style={styles.colhalf1}>Fisica</Text>
                  <Text style={styles.colhalf2}></Text>
                  <Text style={styles.colhalf3}>Digital</Text>
                  <Text style={styles.colhalf4}>X</Text>
                </View>
              </View>
              {/* Fin de la tabla */}

              {/* TABLA AZUL */}
              <Text>{"\n"}</Text>
              <View style={styles.tableBlue}>
                <View style={[styles.rowBlueTitle,]}>
                  <Text style={[styles.textrowBlue,]}>DESCRIPCION DEL SERVICIO COTIZADO</Text>
                </View>
                <View style={[styles.rowBlue,]}>
                  <Text style={styles.col1HeaderBlue}>ITEM</Text>
                  <Text style={styles.col2HeaderBlue}>DESCRIPCION</Text>
                  <Text style={styles.col3HeaderBlue}>CANTIDAD</Text>
                  <Text style={styles.col4HeaderBlue}>VALOR {"\n"} UNITARIO</Text>
                  <Text style={styles.col5HeaderBlue}>VALOR TOTAL</Text>
                </View>
              </View>
              {/* TABLA CONTENIDO */}
              <View style={styles.tableWhite}>
                {equipos.map((equipo, index) => (
                  <View key={index} style={[styles.rowWhite,]}>
                    <Text style={styles.col1White}>{index + 1}</Text>
                    <Text style={styles.colTypeWhite}>{equipo.tipoEqu}</Text>
                    <Text style={styles.col2White}>Servicio de {equipo.tipoServ}. Prestador de servicio: Directo
                      / Marca: Mindray / Modelo:Beneheart D3 / Serial: N.I.
                      Comparación directa de las indicaciones proporcionadas por el
                      equipo patrón de referencia a partir del cual se evalua la
                      desviación de las indicaciones.
                      Puntos de Calibración: N.I/ Servicio No Acreditado
                      Lugar de ejecución: En Sitio.</Text>
                    <Text style={styles.col3White}>{equipo.cantidad}</Text>
                    {Consumibles > 0 && (
                      <Text style={styles.col4White}>$ {(equipo.precio * 0.9).toLocaleString('es-ES')}</Text>
                    )}
                    {Consumibles === 0 && (
                      <Text style={styles.col4White}>$ {equipo.precio.toLocaleString('es-ES')}</Text>
                    )}

                    {Consumibles > 0 && (
                      <Text style={styles.col5White}>$ {(0.9 *equipo.precio * equipo.cantidad).toLocaleString('es-ES')}</Text>
                    )}
                    {Consumibles === 0 && (
                      <Text style={styles.col5White}>$ {(equipo.precio * equipo.cantidad).toLocaleString('es-ES')}</Text>
                    )}

                  </View>
                ))}
                {Consumibles > 0 && (
                  <View style={[styles.rowWhite,]}>
                    <Text style={styles.col1White}>{numEqui + 1}</Text>
                    <Text style={styles.colTypeWhite}>CONSUMIBLES</Text>
                    <Text style={styles.col2White}> </Text>
                    <Text style={styles.col3White}>1</Text>
                    <Text style={styles.col4White}>$ {Number(Consumibles).toLocaleString('es-ES')}</Text>
                    <Text style={styles.col5White}>$ {Number(Consumibles).toLocaleString('es-ES')}</Text>
                  </View>
                )}
                <View style={[styles.rowWhiteNoBorder,]}>
                  <Text style={styles.colEmptyWhite}></Text>
                  <Text style={styles.col4WhiteBorder}><Text style={styles.bold}>Subtotal</Text></Text>
                  <Text style={styles.col5White}>$ {(TOTAL - IVA - Consumibles).toLocaleString('es-ES')}</Text>
                </View>
                <View style={[styles.rowWhiteNoBorder,]}>
                  <Text style={styles.colEmptyWhite}></Text>
                  <Text style={styles.col4WhiteBorder}><Text style={styles.bold}>Otros</Text></Text>
                  <Text style={styles.col5White}>$ 0</Text>
                </View>
                <View style={[styles.rowWhiteNoBorder,]}>
                  <Text style={styles.colEmptyWhite}></Text>
                  <Text style={styles.col4WhiteBorder}><Text style={styles.bold}>Descuentos</Text></Text>
                  <Text style={styles.col5White}>$ 0</Text>
                </View>
                <View style={[styles.rowWhiteNoBorder,]}>
                  <Text style={styles.colEmptyWhite}></Text>
                  <Text style={styles.col4WhiteBorder}><Text style={styles.bold}>IVA 19%</Text></Text>
                  <Text style={styles.col5White}>$ {IVA.toLocaleString('es-ES')}</Text>
                </View>
                <View style={[styles.rowWhiteNoBorder,]}>
                  <Text style={styles.colEmptyWhite}></Text>
                  <Text style={styles.col4WhiteBorder}><Text style={styles.bold}>Total</Text></Text>
                  <Text style={styles.col5White}>$ { Number(TOTAL).toLocaleString('es-ES')}</Text>
                </View>
              </View>
            </View>
            <View style={styles.footer}>
              <Text style={styles.footerText}>Calle 110 No. 22a-104 Provenza /Bucaramanga-Santander.</Text>
              <Text style={styles.footerText}>Correo: info@bioasesores.com / bioasesores.info@gmail.com Telefono: 6076088093- 3004941066</Text>
              <Text style={styles.pageNumber}>
              </Text>
            </View>
            <View style={styles.footerNumber}> <Text style={styles.pageNumber}>pagina {pageIndex + 1}</Text></View>
            {pageIndex < pages.length - 1 && (
              <Page key={`page-break-${pageIndex + 1}`} size="A4" style={styles.page} />
            )}
          </Page>
        ))}

        {/*segunda pagina*/}
        <Page size="A4" style={styles.page} >
          <View style={styles.section}>
            <View style={styles.header}>
              <Image src={logoImg} style={styles.backgroundImageLogo} />
              <View style={styles.titleContainer}>
                <Text style={[styles.title, styles.bold]}>
                  FORMATO COTIZACION{"\n"}
                  SERVICIOS DE CALIBRACION</Text>
              </View>
              <View style={styles.paragraphContainer}>
                <Text style={styles.paragraph}>
                  <Text style={styles.bold}>
                    Identificacion:
                    {"\n"}
                    FC-014
                    {"\n"}
                    Version:0
                    {"\n"}
                    Fecha de aprobacion:
                    {"\n"}
                    2022-08-11{currentDate}
                  </Text>
                </Text>
              </View>
            </View>
            {/* TABLA CONTENIDO */}
            <View style={styles.tableWhite}>
              <Text style={{ textDecoration: 'underline' }}>Forma de pago {"\n"}</Text>
              <Text>50% anticipado junto con la orden de servicio. 50% al finalizar el servicio. Recuerde que para la entrega de los Soportes de calibración, debe estar
                cancelado la totalidad del servicio.</Text>

              <Text>
                Realizar consignación a la Cuenta Corriente No. 09082910314 de Bancolombia a nombre de BIOASESORES S.A.S.
              </Text>
              <Text>
                Si el cliente, llegase a requerir una copia del Certificado de Calibración emitido, tendrá un costo de Quince mil pesos ($15.000) si es requerido en físico y
                Diez mil pesos ($10.000) si es requerido en medio digital y será enviado a través de correo electrónico.
              </Text>
              <Text style={{ textDecoration: 'underline' }}>{"\n"} Validez de la oferta {"\n"}</Text>
              <Text>
                La presente oferta comercial tiene una validez de treinta (30) días calendario.
              </Text>
              <Text style={{ textDecoration: 'underline' }}>{"\n"} Programación de los servicios {"\n"}</Text>
              Tiempo de entrega Certificados: a. de uno a cinco equipos: cinco días hábiles; b. de seis a diez equipos: ocho días hábiles; c. mas de 10 equipos: de
              diez a quince días hábiles.{"\n"}
              Las fechas descritas anteriormente se encuentra sujeta a cambios de acuerdo a la carga de trabajo del laboratorio. En caso que se generen modificaciones
              en los tiempos de entrega, esto será comunicado de manera oportuna.{"\n"}
              <Text>{"\n"}Para la ejecución de los servicios en instalaciones de BIOASESORES S.A.S, el cliente es el responsable de los trámites de transporte, para lo cual debe
                considerar los horarios de atención:{"\n"}
                Lunes a Viernes. 07:30 a 12:30; 13:30 a 17:30.{"\n"}
                Sábados. 08:00 a 11:00.{"\n"}
              </Text>
              <Text style={{ textDecoration: 'underline' }}>{"\n"}Confidencialidad {"\n"}</Text>
              <Text>{"\n"}El laboratorio se hace responsable de toda la información obtenida o creada durante la realización de las actividades de calibración, garantizando por
                medio de acuerdos de confidencialidad con su personal el manejo de la misma. Cuando el laboratorio sea requerido o autorizado por las disposiciones
                contratuales para revelar informació confidencial, el cliente será notificado, salvo que esté prohibido por ley.
                {"\n"}{"\n"}La información contenida en este documento es confidencial ya que puede ser LEGALMENTE PRIVILEGIADA. Esta es de uso exclusivo del (de los)
                destinatario (s) sin la intención de que sea conocida por terceros, por lo tanto, de conformidad con las normas legales vigentes, su intercepción,
                sustracción, extravió, reproducción o uso no autorizado está prohibido a cualquier persona diferente.
              </Text>
              <Text style={{ textDecoration: 'underline' }}>{"\n"}Requisitos para la prestación de los servicios {"\n"}</Text>
              <Text>{"\n"}- Para la ejecución de las actividades de calibración en sitio, el cliente debe disponer de espacios e instalaciones adecuadas para el desarrollo de las
                mismas. En el caso de la calibración de tensiómetros o esfigmomanómetros analógicos se requiere de condiciones ambientales en temperatura entre
                15°C a 25°C. Cuando el cliente no logre cumplir este requerimiento, se hará un descargo de responsabilidad en el certificado de calibración.{"\n"}{"\n"}
                - Para la calibración de pipetas accionadas a pistón, el cliente debe proveer las puntas plásticas. Para una pipeta monocanal de volumen fijo se requiere al
                menos 10 puntas; para una pipeta monocanal de volumen variables se requiere al menos 30 puntas. Para pipetas multicanal, el número de puntas
                requeridas se calcula de acuerdo al número de canales. En el caso que el cliente no suministre las puntas, la calibración se desarrollará con las dispuestas
                por el laboratorio y se hará un descargo de responsabilidad en el certificado de calibración, considerando que el tipo de puntas utilizadas pueden influir en
                el volumen dispensado.{"\n"}{"\n"}
                - Para pipetas a pistón y cuando el equipo lo requiera y lo permita, se realizará el ajuste del volumen dispensado. El ajuste previsto NO corresponde a una
                actividad de mantenimiento{"\n"}{"\n"}
                - Los equipos deben ser enviados limpios y desinfectados. Cuando el laboratorio requiera del manual del instrumento será comunicado a través de los
                canales de contacto dispuesto por el cliente (correo electrónico y/o teléfono).{"\n"}{"\n"}
                - Para la declaración de conformidad de los resultados, el laboratorio ha dispuesto de una serie de herramientas de acuerdo a lo descrito en la Guía ILAC
                G8:2019, las cuales se describen a continuación. Sólo para estos equipos, el laboratorio dispone de emisión de conformidad de los resultados.{"\n"}
              </Text>
            </View>

            {/*tabla azul extra*/}

            <View style={styles.tableBlue}>
              <View style={[styles.rowBlue,]}>
                <Text style={styles.col1HeaderBlueLast}> Tipo de Instrumento</Text>
                <Text style={styles.col2HeaderBlueLast}> Regla de Decisión</Text>
                <Text style={styles.col3HeaderBlueLast}>Especificación</Text>
                <Text style={styles.col4HeaderBlueLast}>Nivel de Riesgo</Text>
              </View>
            </View>
            <View style={styles.tableWhite}>
              <View style={[styles.rowWhite,]}>
                <Text style={styles.col1WhiteLast}>Esfigmomanómetro o tensiómetro analógico</Text>
                <Text style={styles.col2WhiteLast}> Inherente a la norma de {"\n"}
                  referencia.{"\n"}
                  Pasa o Falla
                </Text>
                <Text style={styles.col3WhiteLast}>OIML R16-1: 2002(E) Non-invasive automated {"\n"}
                  sphygmomanometers, Numeral 5.1.1.{"\n"}
                  Esfigmomanómetro o tensiómetro analógico{"\n"}
                  Inherente a la norma de {"\n"}
                  referencia.
                  Pasa o Falla
                  Error máximo permitido para equipos en uso: ±4 {"\n"}
                  mmHg.{"\n"}
                  Error máximo permitido para equipos nuevos: ±3 {"\n"}
                  mmHg</Text>
                <Text style={styles.col4WhiteLast}>Inherente a la {"\n"}
                  norma de {"\n"}
                  referencia
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.footerText}>Calle 110 No. 22a-104 Provenza /Bucaramanga-Santander.</Text>
            <Text style={styles.footerText}>Correo: info@bioasesores.com / bioasesores.info@gmail.com Telefono: 6076088093- 3004941066</Text>
            <Text style={styles.pageNumber}>
            </Text>
          </View>
        </Page>
        {/*tercera pagina*/}
        <Page size="A4" style={styles.page} >
          <View style={styles.section}>
            <View style={styles.header}>
              <Image src={logoImg} style={styles.backgroundImageLogo} />
              <View style={styles.titleContainer}>
                <Text style={[styles.title, styles.bold]}>
                  FORMATO COTIZACION{"\n"}
                  SERVICIOS DE CALIBRACION</Text>
              </View>
              <View style={styles.paragraphContainer}>
                <Text style={styles.paragraph}>
                  <Text style={styles.bold}>
                    Identificacion:
                    {"\n"}
                    FC-014
                    {"\n"}
                    Version:0
                    {"\n"}
                    Fecha de aprobacion:
                    {"\n"}
                    2022-08-11{currentDate}
                  </Text>
                </Text>
              </View>
            </View>
            <View><Text>{"\n"}</Text></View>
            <View style={styles.tableWhite}>
              <View style={[styles.rowWhite,]}>
                <Text style={styles.col1WhiteLast}> Pipetas a Pistón</Text>
                <Text style={styles.col2WhiteLast}> Inherente a la norma de {"\n"}
                  referencia.{"\n"}
                  Pasa o Falla</Text>
                <Text style={styles.col3WhiteLast}> {"\n"}NTC-ISO 8655-2. Equipos Volumétricos {"\n"}
                  Accionados Mediante Pistón. Parte 2: Métodos {"\n"}
                  Gravimétricos Para La Determinación Del Error De {"\n"}
                  Medición. 2014. Numeral 7.2 a 7.5 (La que {"\n"}
                  aplique){"\n"}{"\n"}</Text>
                <Text style={styles.col4WhiteLast}> Inherente a la {"\n"}
                  norma de {"\n"}
                  referencia{"\n"}</Text>
              </View>
              <View style={[styles.rowWhite,]}>
                <Text style={styles.col1WhiteLast}>Termómetros</Text>
                <Text style={styles.col2WhiteLast}> Aceptación Simple.{"\n"}
                  Pasa o Falla</Text>
                <Text style={styles.col3WhiteLast}>{"\n"}Error Máximo Permitido por el fabricante o Error {"\n"}
                  Máximo Permitido por el Proceso{"\n"}{"\n"}</Text>
                <Text style={styles.col4WhiteLast}> &lt; 50 %PFA </Text>
              </View>
              <View style={[styles.rowWhite,]}>
                <Text style={styles.col1WhiteLast}> Manómetros</Text>
                <Text style={styles.col2WhiteLast}> Aceptación Simple.
                  Pasa o Falla</Text>
                <Text style={styles.col3WhiteLast}>{"\n"}Error Máximo Permitido por el fabricante o Error {"\n"}
                  Máximo Permitido por el Proceso{"\n"}{"\n"}</Text>
                <Text style={styles.col4WhiteLast}>&lt; 50 %PFA</Text>
              </View>
            </View>
            <View style={styles.tableWhite}>
              <Text>
                *PFA: Probabilidad de Falsa Aceptación. Para la aplicación de la regla de aceptación simple, se debe cumplir que la incertidumbre expandida sea menor o
                igual a 1/3 del error máximo permitido por el fabricante o proceso. Cuando esto no se pueda cumplir, el laboratorio no emitirá declaración de conformidad,
                excepto cuando el cliente sea quien defina una nueva regla de decisión, la cual será incluida como parte del descargo de responsabilidad.{"\n"}{"\n"}
                - Cuando el cliente no defina por los canales de comunicación que requieren declaración de conformidad de los resultados, se dará por entendido que no
                hace parte de la necesidad.{"\n"}{"\n"}
                - Si el cliente define la regla de aceptación para la emisión de conformidad de los resultados, debo comunicarlo. En estos casos, se emite descargo de
                responsabilidad en el certificado de calibración.{"\n"}{"\n"}
              </Text>
              <Text style={{ textDecoration: 'underline' }}>{"\n"}Canales de Comunicación {"\n"}</Text>
              <Text>{"\n"}El laboratorio dispone de los siguientes canales de comunicación para solventar dudas sobre las cotizaciones enviadas, para obtener soporte técnico o
                para comunicar las respectivas solicitudes, quejas, reclamos y felicitaciones que consideren procedentes:{"\n"}
                Correo electrónico: info@bioasesores.com / a.administrativo@bioasesores.com / bioasesores.info@gmail.com{"\n"}
                Teléfono: (607)6511796- 3004941066{"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                Atentamente{"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                {"\n"}
                <Text style={styles.bold}>{UserGenerator}{"\n"}
                  Asistente administrativo </Text>
              </Text>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.footerText}>Calle 110 No. 22a-104 Provenza /Bucaramanga-Santander.</Text>
            <Text style={styles.footerText}>Correo: info@bioasesores.com / bioasesores.info@gmail.com Telefono: 6076088093- 3004941066</Text>
            <Text style={styles.pageNumber}>
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer >
  );
};

export default PdfGenerator;
