import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {App} from './app';
//import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';


//ReactDOM.render(
//<React.StrictMode>
//    <App />
//  </React.StrictMode>, 
//  document.getElementById("loginDiv")
//);

//ReactDOM.render(<App /> , document.getElementById("root"));
ReactDOM.render(<Router><App /></Router> , document.getElementById("root"));
//ReactDOM.render(<Router><App /></Router>, document.getElementById("loginDiv"));


//reportWebVitals();