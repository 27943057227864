import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';

export function EntitieRegistration({ TodoClick, urldb }) {

    const fetchLugares = async (nameEnti) => {
        console.log('buscando')
        try {
            const response = await axios.get(`${urldb}/lugaresFilter/${nameEnti}`);
            // Maneja la respuesta de tu servicio aquí
            // console.log('Datos de entidades:', response.data);
            // Actualiza las sugerencias con los datos obtenidos
            setSuggestionsList(response.data);
        } catch (error) {
            // Maneja los errores aquí
            console.error('Error al obtener datos de los lugares:', error);
        }
    };

    //const de sugerencias
    //texto de selecion
    const [suggestionValue, setSuggestionValue] = useState('');
    //objeto seleccionado
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    //lista de sujerencias
    const [suggestionsList, setSuggestionsList] = useState([]);

    //fijar nueva lista
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        /*
        const filteredSuggestions = suggestionsLugares.filter(suggestion =>
            suggestion.nombre_lugar.toLowerCase().includes(inputValue)
        );
        setSuggestionsList(filteredSuggestions);
        */

        fetchLugares(inputValue)
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsList([]);
    };

    //para obtener ip seleccionada
    const onSuggestionSelected = (event, { suggestion }) => {
        setSelectedSuggestion(suggestion);
    };

    //se cambia en valor del input de recomendaciones
    //como hospitales y clinicas
    const handleInputChangeSug = (event, { newValue }) => {
        setSuggestionValue(newValue);
        setSelectedSuggestion(null)
        setDatos({ ...datos, nameEnt: suggestionValue })
        setDatos({ ...datos, nameEnt: newValue })
        setDatos({ ...datos, idEnt: 1 })
        //aqui se puede llamar la base de datos para actualziar la lista de sugerencias
    };

    const [datos, setDatos] = useState({
        nombreComercial: '',
        razonSocial: '',
        NIT: '',
        direccion: '',
        correo: '',
        telefono: '',
        ciudad: '',
        idlugar: '',
    })

    const handleInputChange = (event) => {
        //console.log(event.target.name)
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    const enviarDatos = (event) => {
        if (!selectedSuggestion) {
            alert('Por favor, seleccione una ciudad'); // Muestra un mensaje de error al usuario
        } else {



            event.preventDefault()
            if (!datos.nombreComercial || datos.nombreComercial == '') {
                setDatos({
                    ...datos,
                    nombreComercial: datos.razonSocial
                })
            }
            setDatos({
                ...datos,
                idlugar: selectedSuggestion.idlugar
            })
            setDatos({
                ...datos,
                ciudad: selectedSuggestion.nombre_lugar
            })
            if (datos.telefono === '' && datos.correo === '') {
                alert('Por favor, ingrese un correo o telefono.'); // Muestra un mensaje de error al usuario
            } else {
                TodoClick(datos, selectedSuggestion)
            }
        }
    }
    //<label for="razon-social">Razón Social:</label>
    //<input type="text" id="razon-social" name="razon-social" required />

    return (
        <Fragment>
            <div className="container">
                <h1>Registro de Entidad Médica</h1>
                <form className="registration-form" onSubmit={enviarDatos}>
                    <label for="nombre-comercial">Nombre Comercial:</label>
                    <input type="text" onChange={handleInputChange} id="nombreComercial" name="nombreComercial" />
                    <label for="nombre-comercial">Razon social:</label>
                    <input type="text" onChange={handleInputChange} id="razonSocial" name="razonSocial" required />
                    <label for="nit">NIT:</label>
                    <input type="text" onChange={handleInputChange} id="NIT" name="NIT" required />
                    <label for="direccion">Dirección:</label>
                    <input type="text" onChange={handleInputChange} id="direccion" name="direccion" required />
                    <label for="direccion">Correo electronico:</label>
                    <input type="email" onChange={handleInputChange} id="correo" name="correo" />
                    <label for="telefono">Telefono:</label>
                    <input type="text" onChange={handleInputChange} id="telefono" name="telefono" />
                    <label for="ciudad">Ciudad:</label>
                    {/*<input type="text" onChange={handleInputChange} id="ciudad" name="ciudad" required />*/}
                    {/*sugerencia para ciudades obligatorio*/}
                    <Autosuggest
                        id="nombre_lugar"
                        name="ciudad"
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={suggestion => suggestion.nombre_lugar}
                        renderSuggestion={suggestion => <div>
                            <div>{suggestion.nombre_lugar + '(' + suggestion.nombre_lugar_ubicacion + ')'}</div>
                        </div>}
                        onSuggestionSelected={onSuggestionSelected}
                        inputProps={{
                            value: suggestionValue,
                            onChange: handleInputChangeSug,
                        }}
                        required
                    />

                    <button className="btn btn-primary btn-xl" type="submit">Registrar</button>
                </form>
            </div>
        </Fragment>
    );
}
export default EntitieRegistration