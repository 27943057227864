import React, { useState, Fragment, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

export function CreateCotiz({ TodoClick, openAddEnt, exitCotiz,
    goCreatePDF, showEdit, dataCotiz, dataEquip, SendEditedCotiz, urldb }) {

    //datos de la entidad
    const [datos, setDatos] = useState({
        idcotizacion: '',
        idEnt: '',
        nombrecomercial: '',
        mail: '',
        phone: '',
        desc: '',
        dir: '',
        nombre_lugar_entidad: '',
    })

    //datos para editar de los equipos
    const asignarEquipos = () => {
        const nuevosEquipos = dataEquip.map((item) => ({
            iddetalle: item.iddetalle,
            cantidad: item.cantidad,
            tipoEqu: item.tipoequipo,
            tipoServ: item.tipo_servicio,
            marca: item.marcaequipo,
            modelo: '',
            serial: '',
            descrip: item.descripcion,
            precio: item.valorunitario,
            total: (item.cantidad * item.valorunitario),
        }));
        setEquipos(nuevosEquipos);
    };


    //datos colocacion de datos para editar
    useEffect(() => {
        if (showEdit && dataCotiz) {
            setSuggestionValue(dataCotiz.nombrecomercial)
            setDatos({
                idcotizacion: dataCotiz.idcotizacion,
                idEnt: dataCotiz.identidadmedica,
                nombrecomercial: dataCotiz.nombrecomercial,
                mail: dataCotiz.contactoentidad,
                phone: dataCotiz.telefono,
                desc: '',
                dir: dataCotiz.direccion,
                nombre_lugar_entidad: dataCotiz.nombre_lugar,
            })
            asignarEquipos();
            setSelectedSuggestion({
                "identidadmedica": dataCotiz.identidadmedica,
                "nombrecomercial": dataCotiz.nombrecomercial,
                "razonsocial": dataCotiz.razonsocial,
                "nit": dataCotiz.nit,
                "direccion": dataCotiz.direccion,
                "correo": dataCotiz.correo,
                "telefono": dataCotiz.telefono,
                "idlugar": dataCotiz.idlugar,
                "tipo_lugar": dataCotiz.tipo_lugar,
                "nombre_lugar_entidad": dataCotiz.nombre_lugar,
                "ubicacion": dataCotiz.ubicacion,
            });
            setMostrarConsumibles(showEdit && dataCotiz.consumibles !== 0)
            setMostrarCalculoIVA(showEdit && dataCotiz.aplicaiva !== 0)
        }
    }, [showEdit, dataCotiz])

    //listado de equipos a registrar
    const [equipos, setEquipos] = useState([
        {
            iddetalle: '',
            cantidad: 1,
            tipoEqu: '',
            tipoServ: '',
            marca: '',
            modelo: '',
            serial: '',
            descrip: '',
            precio: 0,
            total: 0,
        }
    ]);

    //equipos borrados
    const [deletedEqu, setDeletedEqu] = useState([]);

    //totales de cotizacion e iva entre otras
    const [mostrarCalculoIVA, setMostrarCalculoIVA] = useState(false); // Estado para el checkbox
    const [totalIVA, setTotalIVA] = useState(0); // Estado para el total del IVA
    const [mostrarConsumibles, setMostrarConsumibles] = useState(false); // Nuevo estado para la tabla de consumibles

    //lista quemada, no se usa si hay db
    const [suggestions, setSuggestions] = useState([])
    //sugerencias de hopitales
    //aqui se cargar las sugerencias desde db
    const fetchSuggestions = async (nameEnti) => {
        try {
            const response = await axios.get(`${urldb}/entidadesFilter/${nameEnti}`);
            // Maneja la respuesta de tu servicio aquí
            // console.log('Datos de entidades:', response.data);
            // Actualiza las sugerencias con los datos obtenidos
            setSuggestionsList(response.data);
        } catch (error) {
            // Maneja los errores aquí
            console.error('Error al obtener datos de entidades:', error);
        }
    };

    //const de sugerencias
    //texto de selecion
    const [suggestionValue, setSuggestionValue] = useState('');
    //objeto seleccionado
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    //lista de sujerencias
    const [suggestionsList, setSuggestionsList] = useState([]);

    //fijar nueva lista
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        // === este segmento es si es una lista quemada== 

        /*const filteredSuggestions = suggestions.filter(suggestion =>
            suggestion.nombrecomercial.toLowerCase().includes(inputValue)
        );
        setSuggestionsList(filteredSuggestions);
        */

        //se llama este metodo para actualizar la lista
        // si no filtra correctamente es problema del servicio
        fetchSuggestions(inputValue);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsList([]);
    };

    //para obtener ip seleccionada
    const onSuggestionSelected = (event, { suggestion }) => {
        setSelectedSuggestion(suggestion);
    };


    //se cambia en valor del input de recomendaciones
    //como hospitales y clinicas
    const handleInputChangeSug = (event, { newValue }) => {
        //console.log('se hizo un cambio en sugerencias')
        setSuggestionValue(newValue);
        setSelectedSuggestion(null)
        setDatos({ ...datos, nombrecomercial: suggestionValue })
        setDatos({ ...datos, nombrecomercial: newValue })
        setDatos({ ...datos, idEnt: 1 })
        /*
        if(selectedSuggestion){
            setDatos({ ...datos, mail: selectedSuggestion.contactoentidad })
            setDatos({ ...datos, phone: selectedSuggestion.telefono })
        }
        */
        //aqui se puede llamar la base de datos para actualziar la lista de sugerencias

    };

    //mostras por consola datos guardados
    const printData = (event) => {
        //entidad seleccionada
        console.log(selectedSuggestion)
        //datos del formularios
        console.log(datos)
        console.log(equipos)
        console.log()
    }

    const handleInputChange = (event) => {
        //console.log(event.target.name)
        //console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    //eventos para las tablas de equipos
    const handleTableRowChange = (event, rowIndex) => {
        const { name, value } = event.target;
        const nuevosEquipos = [...equipos];
        nuevosEquipos[rowIndex][name] = value;
        // Calcular el total automáticamente
        nuevosEquipos[rowIndex].total = nuevosEquipos[rowIndex].cantidad * nuevosEquipos[rowIndex].precio;
        setEquipos(nuevosEquipos);
    };

    const agregarEquipo = () => {
        // Agregar una nueva fila de equipo vacía
        setEquipos([...equipos, {}]);
    };

    const borrarEquipo = (index) => {
        const deletedEquipo = equipos.splice(index, 1)[0]; // Elimina el equipo y lo almacena en deletedEquipo
        if (deletedEquipo.iddetalle) {
            // Si el equipo tiene un precio definido, agrega el precio a la lista de precios eliminados
            console.log("se borro el detalle de id: " + deletedEquipo.iddetalle)
            setDeletedEqu([...deletedEqu, deletedEquipo.iddetalle]);
        }
        setEquipos([...equipos]); // Actualiza la lista de equipos
    };

    //calculo de total y condiciones adicionales

    const calcularTotal = () => {
        let total = 0;
        if (mostrarCalculoIVA) {
            // Si el checkbox está marcado, sumar el total del IVA al total
            total += parseFloat(totalIVA);
        }
        if (mostrarConsumibles) {
            // Si el checkbox está marcado, sumar el total del IVA al total
            total += parseFloat(calcularTotalConsumibles());
            total += (equipos.reduce((acc, equipo) => acc + parseFloat(equipo.total), 0)) * 0.9;
        } else {
            total += equipos.reduce((acc, equipo) => acc + parseFloat(equipo.total), 0);
        }
        return total.toFixed(2);
    };

    const calcularTotalConsumibles = () => {
        const totalEquipos = equipos.reduce((acc, equipo) => acc + equipo.total, 0);
        return (totalEquipos * 0.10).toFixed(2); // Calcular el 10% de la suma de los totales de los equipos
    };

    /* no se coloco porque ya se hizo este calculo
    const calcularTotalIVAIndividual = () => {
        let total = 0;
        equipos.forEach((equipo) => {
            total += equipo.total * 0.16; // Sumar el IVA individual de cada equipo
        });
        return total.toFixed(2);
    };
    */
    const calcularTotalIVA = () => {
        let total = 0;
        if (mostrarConsumibles) {
            // Si el checkbox de consumibles está marcado, calcular el IVA solo sobre los consumibles
            total += calcularTotalConsumibles() * 0.19;
        } else {
            // Si el checkbox de consumibles no está marcado, calcular el IVA sobre el total de equipos
            total += equipos.reduce((acc, equipo) => acc + equipo.total, 0) * 0.19;
        }
        setTotalIVA(total)
        return total;
    };

    useEffect(() => {
        if (equipos.length === 0) {
            // Si no hay filas de equipos, añadir una por defecto
            setEquipos([
                {
                    cantidad: 1,
                    nombre: '',
                    tipo: '',
                    precio: 0,
                    total: 0 // Agregamos una propiedad 'total'
                }
            ]);
        }
        // Calcular el total del IVA cuando cambie el estado de los equipos
        calcularTotalIVA();
        const confirmExit = (e) => {
            // Mensaje de advertencia personalizado
            const confirmationMessage = '¿Estás seguro de que deseas abandonar esta página? Los cambios no guardados se perderán.';

            // Si se detecta un cambio en la página o se cierra la ventana, muestra el mensaje
            e.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        // Agrega un oyente de eventos para el evento beforeunload
        window.addEventListener('beforeunload', confirmExit);

        // Agrega un oyente de eventos para el evento popstate (cambio de página)
        window.addEventListener('popstate', confirmExit);

        return () => {
            // Limpia los oyentes de eventos al desmontar el componente
            window.removeEventListener('beforeunload', confirmExit);
            window.removeEventListener('popstate', confirmExit);
        };
    }, [equipos, mostrarCalculoIVA, mostrarConsumibles]);

    const enviarDatos = (event) => {
        let ivaToUse = mostrarCalculoIVA ? totalIVA : 0;
        let consumiblesToUse = mostrarConsumibles ? calcularTotalConsumibles() : 0;
        if (equipos.length === 0) {
            alert('Por favor, agregue al menos un equipo.'); // Muestra un mensaje de error al usuario
        } else if (equipos.some((equipo) => equipo.cantidad === '' ||
            equipo.precio === '' ||
            equipo.tipoEqu === '' ||
            equipo.tipoServ === '') || !selectedSuggestion) {
            alert('Por favor, complete todos los campos obligatorios.'); // Muestra un mensaje de error al usuario
        } else {
            if (showEdit) {
                event.preventDefault()
                SendEditedCotiz(datos, equipos, selectedSuggestion, ivaToUse, consumiblesToUse, calcularTotal(), deletedEqu)
            } else {
                if (datos.telefono === '' && datos.correo === '') {
                    alert('Por favor, agregue un correo o telefono de contacto.')
                } else {
                    event.preventDefault()
                    TodoClick(datos, equipos, selectedSuggestion, ivaToUse, consumiblesToUse, calcularTotal())
                }
            }
        }
    }

    const clickToOpenCreateEntitie = (event) => {
        event.preventDefault()
        openAddEnt()
    }

    const clickExitCreateCotiz = (event) => {
        event.preventDefault()
        exitCotiz()
    }

    const clickCreatePDF = (event) => {
        let ivaToUse = mostrarCalculoIVA ? totalIVA : 0;
        let consumiblesToUse = mostrarConsumibles ? calcularTotalConsumibles() : 0;
        if (equipos.length === 0) {
            alert('Por favor, agregue al menos un equipo.'); // Muestra un mensaje de error al usuario
        } else if (equipos.some((equipo) => equipo.cantidad === '' ||
            equipo.precio === '' ||
            equipo.tipoEqu === '' ||
            equipo.tipoServ === '') || !selectedSuggestion) {
            alert('Por favor, complete todos los campos obligatorios.'); // Muestra un mensaje de error al usuario
        } else {
            event.preventDefault()
            goCreatePDF(datos, equipos, selectedSuggestion, ivaToUse, consumiblesToUse, calcularTotal(), dataCotiz.idcotizacion)
        }
    }

    return (
        <Fragment>
            {showEdit && (
                <h1>Editar cotización</h1>
            )}
            {!showEdit && (
                <h1>Crear cotización</h1>
            )}
            <section className="registro">
                {/* Tu formulario y contenido principal */}
                <form className='formulario-doble' onSubmit={enviarDatos}>
                    <div className='shadow-div'>
                        <div className='columna' >
                            <h1>Información cliente</h1>
                            <label for="nombre">Nombre del Cliente o Entidad:</label>
                            {/*<input type="text" id="nombrecomercial" name="nombrecomercial" onChange={handleInputChange} required />*/}

                            <Autosuggest
                                id="nombrecomercial"
                                name="nombrecomercial"
                                styles={{ border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse' }}
                                suggestions={suggestionsList}
                                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={onSuggestionsClearRequested}
                                getSuggestionValue={suggestion => suggestion.nombrecomercial}
                                renderSuggestion={suggestion => <div>
                                    <div>{suggestion.nombrecomercial + '(' + suggestion.nombre_lugar_entidad + ')'}</div>
                                </div>}
                                onSuggestionSelected={onSuggestionSelected}
                                inputProps={{
                                    value: suggestionValue,
                                    onChange: handleInputChangeSug,
                                }}
                            />

                            <label for="correo" >Correo Electrónico:</label>
                            <p>Recomendacion: {selectedSuggestion ? selectedSuggestion.correo : "?"}</p>
                            <input type="email" value={datos.mail} id="mail" name="mail" onChange={handleInputChange} required>

                            </input>
                            <label for="telefono" >Telefono de contacto: </label>
                            <p>Recomendacion: {selectedSuggestion ? selectedSuggestion.telefono : "?"}</p>
                            <input type="number" value={datos.phone} id="phone" name="phone" onChange={handleInputChange} required>

                            </input>
                            <label for="descripcion">Descripción:</label>
                            <textarea id="desc" name="desc" onChange={handleInputChange} ></textarea>
                        </div>
                    </div>
                    <div className='shadow-div'>
                        <div className='columna'>
                            <h1>Datos de la entidad</h1>
                            <table>
                                <tr>
                                    <th>Dirección:</th>
                                    <br />
                                    <td className="celda-datos-enti">{selectedSuggestion ? selectedSuggestion.direccion : "Sin seleccionar"}</td>
                                </tr>
                                <tr>
                                    <th id="ciudad" scope="row">Ciudad:</th>
                                    <br />
                                    <td className="celda-datos-enti">{selectedSuggestion ? selectedSuggestion.nombre_lugar_entidad : "Sin seleccionar"}</td>
                                </tr>
                            </table>
                            <table className='tabla-total'>
                                <h1 style={{ whiteSpace: "nowrap" }}>Costo de pedido</h1>
                                <tbody>
                                    <tr>
                                        <td>Consumibles</td>
                                        <td>${mostrarConsumibles ? calcularTotalConsumibles() : "0.00"}</td> {/* Ajuste aquí */}
                                    </tr>
                                    <tr>
                                        <td>IVA</td>
                                        <td>${mostrarCalculoIVA ? totalIVA.toFixed(2) : "0.00"}</td> {/* Ajuste aquí */}
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <td>${calcularTotal()}</td>
                                    </tr>
                                </tbody>
                            </table>
                            {showEdit && (
                                <button onClick={clickCreatePDF} style={{ background: "#2794bc", width: '250px' }} className="btn btn-primary">Guardar y Exportar PDF  <img src="bases images/GenWPDF.png" alt="Agregar" /></button>
                            )}
                        </div>
                    </div>
                    {/*<button className='btn btn-primary' type='submit'>Guardar cotizacion</button>*/}
                </form>
                <div classNameName="container">
                    <h1>Listado de equipos</h1>
                    <table style={{ maxWidth: '50px' }}>
                        <thead>
                            <tr>
                                <th>Unid.</th>
                                <th>Tipo equipo</th>
                                <th>Tipo de Servicio</th>
                                <th>Marca</th>
                                <th>Modelo</th>
                                <th>Serial</th>
                                <th>Descrip.</th>
                                <th>Precio</th>
                                <th>Precio unit.</th>
                                <th>SubTotal</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {equipos.map((equipo, index) => (
                                <tr key={index}>
                                    <td>
                                        <input
                                            type="number"
                                            name="cantidad"
                                            value={equipo.cantidad}
                                            style={{ width: '40px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse' }}
                                            className="input-cantidad"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                            min={0}
                                            required
                                        />
                                    </td >
                                    <td>
                                        <input
                                            type="text"
                                            name="tipoEqu"
                                            value={equipo.tipoEqu}
                                            className="input-nombre"
                                            style={{ border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse' }}
                                            onChange={(event) => handleTableRowChange(event, index)}
                                            list="typeEquipList"
                                            required
                                        />
                                        <datalist id="typeEquipList">
                                            <option value="AGITADOR DE MAZZINI">AGITADOR DE MAZZINI</option>
                                            <option value="AUTOCLAVE">AUTOCLAVE</option>
                                            <option value="BALANZA ANALITICA">BALANZA ANALITICA</option>
                                            <option value="BALANZA DIGITAL">BALANZA DIGITAL</option>
                                            <option value="BALANZA GRAMERA">BALANZA GRAMERA</option>
                                            <option value="BALANZA PEDIATRICA">BALANZA PEDIATRICA</option>
                                            <option value="BAÑO SEROLOGICO">BAÑO SEROLOGICO</option>
                                            <option value="BASCULA ANALÓGICA">BASCULA ANALÓGICA</option>
                                            <option value="BASCULA CON TALLIMETRO">BASCULA CON TALLIMETRO</option>
                                            <option value="BASCULA DIGITAL">BASCULA DIGITAL</option>
                                            <option value="BOMBA DE INFUSION">BOMBA DE INFUSION</option>
                                            <option value="CENTRIFUGA">CENTRIFUGA</option>
                                            <option value="CONCENTRADOR DE OXIGENO">CONCENTRADOR DE OXIGENO</option>
                                            <option value="DESFIBIRLADOR">DESFIBIRLADOR</option>
                                            <option value="DESFIBIRLADOR DEA">DESFIBIRLADOR DEA</option>
                                            <option value="DOPPLER FETAL">DOPPLER FETAL</option>
                                            <option value="ECOGRAFO">ECOGRAFO</option>
                                            <option value="ELECTROCAUTERIO">ELECTROCAUTERIO</option>
                                            <option value="ELECTROCARDIOGRAFO">ELECTROCARDIOGRAFO</option>
                                            <option value="ESPIROMETRO">ESPIROMETRO</option>
                                            <option value="FLUJOMETRO">FLUJOMETRO</option>
                                            <option value="GLUCOMETRO">GLUCOMETRO</option>
                                            <option value="INCUBADORA DE BIOLOGICOS">INCUBADORA DE BIOLOGICOS</option>
                                            <option value="NEBULIZADOR">NEBULIZADOR</option>
                                            <option value="MANOMETRO">MANOMETRO</option>
                                            <option value="MAQUINA DE ANESTESIA">MAQUINA DE ANESTESIA</option>
                                            <option value="MICROCENTRIFUGA">MICROCENTRIFUGA</option>
                                            <option value="MONITOR DE SIGNOS VITALES">MONITOR DE SIGNOS VITALES</option>
                                            <option value="MONITOR FETAL">MONITOR FETAL</option>
                                            <option value="PHMETRO">PHMETRO</option>
                                            <option value="PIPETA A PISTÓN DE VOLUMEN FIJO">PIPETA A PISTÓN DE VOLUMEN FIJO</option>
                                            <option value="MICROPIPETA VARIABLE">MICROPIPETA VARIABLE</option>
                                            <option value="PIPETA MULTICANAL A PISTÓN DE VOLUMEN VARIABLE">PIPETA MULTICANAL A PISTÓN DE VOLUMEN VARIABLE</option>
                                            <option value="PULSIOXIMETRO">PULSIOXIMETRO</option>
                                            <option value="REGULADOR DE OXIGENO CON FLUJÓMETRO">REGULADOR DE OXIGENO CON FLUJÓMETRO</option>
                                            <option value="SUCCIONADOR">SUCCIONADOR</option>
                                            <option value="TENSIOMETRO ANALOGICO / ESFIGMOMANOMETRO">TENSIOMETRO ANALOGICO / ESFIGMOMANOMETRO</option>
                                            <option value="TENSIOMETRO DIGITAL">TENSIOMETRO DIGITAL</option>
                                            <option value="TERMOHIGRÓMETRO">TERMOHIGRÓMETRO</option>
                                            <option value="TERMOHIGROMETRO ANALÓGICO">TERMOHIGROMETRO ANALÓGICO</option>
                                            <option value="TERMOHIGROMETRO DIGITAL">TERMOHIGROMETRO DIGITAL</option>
                                            <option value="TERMOMETRO DIGITAL">TERMOMETRO DIGITAL</option>
                                            <option value="TERMOMETRO INFRARROJO">TERMOMETRO INFRARROJO</option>
                                            <option value="TERMOMETRO DE PUNZÓN">TERMOMETRO DE PUNZÓN</option>
                                            <option value="ULTRASONIDO DE TERAPIA">ULTRASONIDO DE TERAPIA</option>
                                            <option value="VENTILADOR MECÁNICO">VENTILADOR MECÁNICO</option>
                                            <option value="DENSIMETRO">DENSIMETRO</option>
                                            <option value="DATALOGGER DE TEMPERATURA Y HUMEDAD">DATALOGGER DE TEMPERATURA Y HUMEDAD</option>
                                            <option value="REGULADOR DE OXÍGENO">REGULADOR DE OXÍGENO</option>
                                        </datalist>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="tipoServ"
                                            value={equipo.tipoServ}
                                            style={{ width: '100px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse' }}
                                            className="input-tipo"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                            list="typeServList"
                                            required
                                        />
                                        <datalist id="typeServList">
                                            <option value="Mantenimiento">Mantenimiento</option>
                                            <option value="Calibración">Calibración</option>
                                            <option value="Caracterización"></option>
                                            <option value="Diagnóstico">Diagnóstico</option>
                                            <option value="Verificación">Diagnóstico</option>
                                            <option value="Validación">Diagnóstico</option>
                                        </datalist>
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="marca"
                                            value={equipo.marca}
                                            style={{ width: '80px' }}
                                            className="input-tipo"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="modelo"
                                            value={equipo.modelo}
                                            style={{ width: '80px' }}
                                            className="input-tipo"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="serial"
                                            value={equipo.serial}
                                            style={{ width: '80px' }}
                                            className="input-tipo"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            name="descrip"
                                            value={equipo.descrip}
                                            style={{ width: '150px' }}
                                            className="input-tipo"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="number"
                                            name="precio"
                                            value={equipo.precio}
                                            style={{ width: '70px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse' }}
                                            className="input-precio"
                                            onChange={(event) => handleTableRowChange(event, index)}
                                            min={0}
                                            required
                                        />
                                    </td >
                                    {mostrarConsumibles && (
                                        <td type="number"
                                            name="precio"
                                            value={equipo.precio}
                                            style={{ width: '100px' }}
                                            className="input-precio">{equipo.precio * 0.9}</td>
                                    )}
                                    {!mostrarConsumibles && (
                                        <td type="number"
                                            name="precio"
                                            value={equipo.precio}
                                            style={{ width: '100px' }}
                                            className="input-precio">{equipo.precio}</td>
                                    )}
                                    {mostrarConsumibles && (
                                        <td type="number"
                                            style={{ width: '100px' }}
                                            className="total">{equipo.total * 0.9}</td>
                                    )}
                                    {!mostrarConsumibles && (
                                        <td type="number"
                                            style={{ width: '100px' }}
                                            className="total">${equipo.total}</td>
                                    )}

                                    <td>
                                        <button className="btn-delete" onClick={() => borrarEquipo(index)}>Borrar</button>
                                    </td>
                                </tr>
                            ))}

                            {mostrarConsumibles && (
                                <tr>
                                    <td type="number" name="cantidad" style={{ width: '40px' }} className="input-cantidad">
                                        1
                                    </td >
                                    <td type="text" name="tipoEqu" className="input-nombre">
                                        Consumibles
                                    </td>
                                    <td type="text" name="tipoServ" style={{ width: '100px' }}>
                                    </td>
                                    <td type="text" name="marca" style={{ width: '80px' }} className="input-tipo">
                                    </td>
                                    <td type="text"
                                        name="modelo"
                                        style={{ width: '80px' }}
                                        className="input-tipo">
                                    </td>
                                    <td type="text"
                                        name="serial"
                                        style={{ width: '80px' }}
                                        className="input-tipo">
                                    </td>
                                    <td type="text"
                                        name="descrip"
                                        style={{ width: '150px' }}
                                        className="input-tipo">
                                        valor consumibles
                                    </td>
                                    <td type="number"
                                        name="precio"
                                        style={{ width: '70px' }}
                                        className="input-precio"
                                        min={0}>
                                    </td>
                                    <td className="total">${calcularTotalConsumibles()}</td>
                                </tr>
                            )}
                            {!mostrarConsumibles && (
                                <tr>
                                    <td type="number" name="cantidad" style={{ width: '40px' }} className="input-cantidad">
                                        -
                                    </td >
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <a id="btn-add" className="btn btn-primary" onClick={agregarEquipo}>Agregar Equipo</a>
                    <div classNameName="hidden-row" id="add">
                    </div>
                    <div style={{ alignItems: 'right', display: 'flex', width: '150%' }}>
                        <div className='shadow-div' style={{ alignItems: 'right', display: 'flex', width: '100%', borderRadius: '6px', }}>
                            <div className="bottom-cotiz-diver " style={{ padding: '20px', alignItems: 'right', marginLeft: 'auto' }}>
                                {/* Checkbox para mostrar/ocultar tabla de consumibles */}
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={mostrarCalculoIVA}
                                        onChange={() => setMostrarCalculoIVA(!mostrarCalculoIVA)}
                                    />
                                    Cobrar de IVA
                                </label>
                                {/* Tabla de cálculo de IVA */}
                                {mostrarCalculoIVA && (
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th style={{ whiteSpace: 'nowrap' }}>Total de IVA</th>
                                                <td>${totalIVA}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                            <div className="bottom-cotiz-diver" style={{ padding: '20px', alignItems: 'right', marginLeft: 'auto', }}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={mostrarConsumibles}
                                        onChange={() => setMostrarConsumibles(!mostrarConsumibles)}
                                    />
                                    Cobrar Consumibles
                                </label>
                                {/* Tabla de Consumibles */}
                                {mostrarConsumibles && (
                                    <table>
                                        <tbody>
                                            <tr>

                                                <th>Total de Consumibles</th>
                                                <td>${calcularTotalConsumibles()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>

                        <div className="bottom-cotiz-diver" style={{
                            padding: '20px', borderRadius: '6px', boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)',
                            backgroundColor: '#257db4', color: 'white'
                        }}>
                            {/* Tabla de total style={{color: '#FFFFFF', backgroundColor: '#454646',borderRadius: '6px' }}*/}

                            <table className='tabla-total'>
                                <tbody >
                                    <tr >
                                        <td>Consumibles</td>
                                        <td>${mostrarConsumibles ? calcularTotalConsumibles() : "0.00"}</td> {/* Ajuste aquí */}
                                    </tr>
                                    <tr>
                                        <td>Sub Total</td>
                                        {mostrarConsumibles && (
                                            <td>${((equipos.reduce((acc, equipo) => acc + parseFloat(equipo.total), 0)) * 0.9).toFixed(2)}</td>
                                        )}
                                        {!mostrarConsumibles && (
                                            <td>${(equipos.reduce((acc, equipo) => acc + parseFloat(equipo.total), 0)).toFixed(2)}</td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td>IVA</td>
                                        <td>${mostrarCalculoIVA ? totalIVA.toFixed(2) : "0.00"}</td> {/* Ajuste aquí */}
                                    </tr>
                                    <tr>
                                        <th>Total</th>
                                        <td>${calcularTotal()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='formulario-doble' style={{ width: '70%' }}>
                    <div className='columna' >
                        {showEdit && (
                            <button onClick={enviarDatos}
                                className="columna-botones"
                                style={{ backgroundColor: '#28a5b2', color: 'white', textAlign: 'right' }}>
                                <img src="bases images/saveicon.png" alt="Agregar" /> Actualizar Cotización</button>
                        )}
                        {!showEdit && (
                            <button onClick={enviarDatos}
                                className="columna-botones"
                                style={{ backgroundColor: '#28a5b2', color: 'white', textAlign: 'right' }}>
                                <img src="bases images/saveicon.png" alt="Agregar" /> Guardar Cotización</button>
                        )}
                    </div>
                    <div className='columna'>
                        <button onClick={clickExitCreateCotiz}
                            className="columna-botones"
                            style={{ backgroundColor: '#e74c3c', color: 'white', textAlign: 'right' }}>
                            <img src="bases images/iconCancelWhite.png" alt="Agregar" /> Cancelar</button>
                    </div>
                </div>
            </section>

            {/* menu lateral */}
            <aside className="columna-delgada">
                <div className='shadow-div'>
                    <div className="botones">
                        <h1>Opciones</h1>
                        <div className="botones">
                            {showEdit && (
                                <button onClick={enviarDatos} className="columna-botones"><img src="bases images/saveicon.png" alt="Agregar" /> Actualizar Cotización</button>
                            )}
                            {!showEdit && (
                                <button onClick={enviarDatos} className="columna-botones"><img src="bases images/saveicon.png" alt="Agregar" /> Guardar Cotización</button>
                            )}

                            <button onClick={clickToOpenCreateEntitie} id="btnAddEntitie" className="columna-botones"><img src="bases images/adduserIcon.png" alt="Agregar" /> Agregar Entidad</button>
                            {/*<button onClick={clickCreatePDF} className="columna-botones"><img src="bases images/adduserIcon.png" alt="Agregar" /> Exportar PDF</button>*/}
                            <button onClick={clickExitCreateCotiz} className="columna-botones"><img src="bases images/iconCancel.png" alt="Agregar" /> Cancelar Cotización</button>
                            {/* <button onClick={printData} className="columna-botones"><img src="bases images/iconCancel.png" alt="Agregar" /> Mostrar datos actuales consola </button>*/}
                        </div>
                        {/* Tus botones en el aside */}
                    </div>
                </div>
            </aside>

        </Fragment>
    );
}
export default CreateCotiz