import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

export function ManagementEntitites({ goToCreatEntitie, urldb }) {


    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    const [entidades, setEntities] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('nombrecomercial');

    const clickSearch = async () => {
        //console.log('Valor de búsqueda:', searchValue);
        //console.log('Valor seleccionado:', selectedOption);
        const columna = selectedOption;
        var valor = searchValue;
        console.log('buscando')
        /*eliminar este if si de formatea en api
        if(selectedOption === "fecha"){
            valor = valor + 'T05:00:00.000Z'
            console.log('convert')
        }*/
        try {
            const response = await axios.get(urldb + `/entidades?columna=${columna}&valor=${valor}`);
            setEntities(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los resultados:', error);
        }
    };


    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(urldb + '/entidades')
            .then((response) => {
                setEntities(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener usuarios:', error);
            });
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...
        if (orderBy) {
            entidades.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);

    const clickEntitieNavCreate = (event) => {
        event.preventDefault()
        goToCreatEntitie()
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };

    return (
        <Fragment>
            <h1>Listado de entidades</h1>
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                        <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} />
                        <button style={{ marginRight: "10px" }} id="search-button"
                            className='btn-icono' onClick={clickSearch}>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="nombrecomercial">nombre comercial</option>
                            <option value="nit">NIT</option>
                            <option value="razonsocial">Razon social</option>
                            <option value="telefono">telefono</option>
                        </select>
                    </div>
                    <div className="headerMenu">
                        <h1></h1>
                        <button className="btn btn-primary" onClick={clickEntitieNavCreate}>Crear nueva entidad</button>
                    </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('nombreEntidad')}
                                className={orderBy === 'nombreEntidad' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Nombre_Entidad
                            </th>
                            <th onClick={() => handleHeaderClick('razonSocial')}
                                className={orderBy === 'razonSocial' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Razon_Social
                            </th>
                            <th onClick={() => handleHeaderClick('direccion')}
                                className={orderBy === 'direccion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Direccion
                            </th>
                            <th onClick={() => handleHeaderClick('correo')}
                                className={orderBy === 'correo' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Correo
                            </th>
                            <th onClick={() => handleHeaderClick('telefono')}
                                className={orderBy === 'telefono' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Telefono
                            </th>
                            <th onClick={() => handleHeaderClick('ciudad')}
                                className={orderBy === 'ciudad' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Ciudad
                            </th>
                            <th onClick={() => handleHeaderClick('nit')}
                                className={orderBy === 'nit' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                NIT
                            </th>
                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entidades.map((entidad, index) => (
                            <tr key={index}>
                                <td>{entidad.nombrecomercial}</td>
                                <td>{entidad.razonsocial}</td>
                                <td>{entidad.direccion}</td>
                                <td>{entidad.correo}</td>
                                <td>{entidad.telefono}</td>
                                <td>{entidad.nombre_lugar_entidad}</td>
                                <td>{entidad.nit}</td>
                                <td><button className='btn' value={entidad.identidadmedica}>Editar</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </Fragment>
    )
}
export default ManagementEntitites