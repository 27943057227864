import React, { useState, Fragment } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

export function EquipRegistrationPop({ TodoClick, urldb }) {

    const [datos, setDatos] = useState({
        idEnt: '',
        nameEnt: '',
        tipeEquip: '',
        serial: '',
        marca: '',
        model: ''
    })

    //sugerencias de hopitales

    //lista quemada, no se usa si hay db
    const [suggestions, setSuggestions] = useState([])
    //sugerencias de hopitales

    //aqui se cargar las sugerencias desde db
    const fetchSuggestions = async (nameEnti) => {
        try {
            const response = await axios.get(`${urldb}/entidadesFilter/${nameEnti}`);
            // Maneja la respuesta de tu servicio aquí
            // console.log('Datos de entidades:', response.data);
            // Actualiza las sugerencias con los datos obtenidos
            setSuggestionsList(response.data);
        } catch (error) {
            // Maneja los errores aquí
            console.error('Error al obtener datos de entidades:', error);
        }
    };

    //const de sugerencias
    //texto de selecion
    const [suggestionValue, setSuggestionValue] = useState('');
    //objeto seleccionado
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    //lista de sujerencias
    const [suggestionsList, setSuggestionsList] = useState([]);

    //fijar nueva lista
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        // === este segmento es si es una lista quemada== 

        /*const filteredSuggestions = suggestions.filter(suggestion =>
            suggestion.nombrecomercial.toLowerCase().includes(inputValue)
        );
        setSuggestionsList(filteredSuggestions);
        */

        //se llama este metodo para actualizar la lista
        // si no filtra correctamente es problema del servicio
        fetchSuggestions(inputValue);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsList([]);
    };

    //para obtener ip seleccionada
    const onSuggestionSelected = (event, { suggestion }) => {
        setSelectedSuggestion(suggestion);
    };


    //se cambia en valor del input de recomendaciones
    //como hospitales y clinicas
    const handleInputChangeSug = (event, { newValue }) => {
        //console.log('se hizo un cambio en sugerencias')
        setSuggestionValue(newValue);
        setSelectedSuggestion(null)
        setDatos({ ...datos, nombrecomercial: suggestionValue })
        setDatos({ ...datos, nombrecomercial: newValue })
        setDatos({ ...datos, idEnt: 1 })


        //aqui se puede llamar la base de datos para actualziar la lista de sugerencias

    };

    const handleInputChange = (event) => {
        console.log(event.target.name)
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    const enviarDatos = (event) => {
        if (!selectedSuggestion) {
            alert('Por favor, seleccione una entidad Propietaria.'); // Muestra un mensaje de error al usuario
        } else {
            event.preventDefault()
            TodoClick(datos, selectedSuggestion)
        }
    }
    //<label for="razon-social">Razón Social:</label>
    //<input type="text" id="razon-social" name="razon-social" required />

    return (
        <Fragment>
            <div className="container">
                <h1>Registro Equipo</h1>
                <form className="registration-form" onSubmit={enviarDatos}>
                    <label for="nombre-comercial">Entidad Propietaria</label>
                    <Autosuggest
                        id="nombrecomercial"
                        name="nombrecomercial"
                        suggestions={suggestionsList}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        getSuggestionValue={suggestion => suggestion.nombrecomercial}
                        renderSuggestion={suggestion => <div>
                            <div>{suggestion.nombrecomercial + '(' + suggestion.nombre_lugar_entidad + ')'}</div>
                        </div>}
                        onSuggestionSelected={onSuggestionSelected}
                        inputProps={{
                            value: suggestionValue,
                            onChange: handleInputChangeSug,
                        }}
                        required
                    />
                    {/* <input  onChange={handleInputChange} id="nameEnt" name="nameEnt" type="text" required="required" data-validation-required-message="Please enter the horse name." />*/}
                    <label>Serial</label>
                    <input onChange={handleInputChange} id="serial" name="serial" type="text" required="required" data-validation-required-message="Please enter the horse name." />
                    <p class="help-block text-danger"></p>
                    <label>Tipo equipo</label>
                    <input type="text" onChange={handleInputChange} id="tipeEquip" name="tipeEquip" list="typeEquipList" required />
                    <datalist id="typeEquipList">
                        <option value="AGITADOR DE MAZZINI">AGITADOR DE MAZZINI</option>
                        <option value="AUTOCLAVE">AUTOCLAVE</option>
                        <option value="BALANZA ANALITICA">BALANZA ANALITICA</option>
                        <option value="BALANZA DIGITAL">BALANZA DIGITAL</option>
                        <option value="BALANZA GRAMERA">BALANZA GRAMERA</option>
                        <option value="BALANZA PEDIATRICA">BALANZA PEDIATRICA</option>
                        <option value="BAÑO SEROLOGICO">BAÑO SEROLOGICO</option>
                        <option value="BASCULA ANALÓGICA">BASCULA ANALÓGICA</option>
                        <option value="BASCULA CON TALLIMETRO">BASCULA CON TALLIMETRO</option>
                        <option value="BASCULA DIGITAL">BASCULA DIGITAL</option>
                        <option value="BOMBA DE INFUSION">BOMBA DE INFUSION</option>
                        <option value="CENTRIFUGA">CENTRIFUGA</option>
                        <option value="CONCENTRADOR DE OXIGENO">CONCENTRADOR DE OXIGENO</option>
                        <option value="DESFIBIRLADOR">DESFIBIRLADOR</option>
                        <option value="DESFIBIRLADOR DEA">DESFIBIRLADOR DEA</option>
                        <option value="DOPPLER FETAL">DOPPLER FETAL</option>
                        <option value="ECOGRAFO">ECOGRAFO</option>
                        <option value="ELECTROCAUTERIO">ELECTROCAUTERIO</option>
                        <option value="ELECTROCARDIOGRAFO">ELECTROCARDIOGRAFO</option>
                        <option value="ESPIROMETRO">ESPIROMETRO</option>
                        <option value="FLUJOMETRO">FLUJOMETRO</option>
                        <option value="GLUCOMETRO">GLUCOMETRO</option>
                        <option value="INCUBADORA DE BIOLOGICOS">INCUBADORA DE BIOLOGICOS</option>
                        <option value="NEBULIZADOR">NEBULIZADOR</option>
                        <option value="MANOMETRO">MANOMETRO</option>
                        <option value="MAQUINA DE ANESTESIA">MAQUINA DE ANESTESIA</option>
                        <option value="MICROCENTRIFUGA">MICROCENTRIFUGA</option>
                        <option value="MONITOR DE SIGNOS VITALES">MONITOR DE SIGNOS VITALES</option>
                        <option value="MONITOR FETAL">MONITOR FETAL</option>
                        <option value="PHMETRO">PHMETRO</option>
                        <option value="PIPETA A PISTÓN DE VOLUMEN FIJO">PIPETA A PISTÓN DE VOLUMEN FIJO</option>
                        <option value="MICROPIPETA VARIABLE">MICROPIPETA VARIABLE</option>
                        <option value="PIPETA MULTICANAL A PISTÓN DE VOLUMEN VARIABLE">PIPETA MULTICANAL A PISTÓN DE VOLUMEN VARIABLE</option>
                        <option value="PULSIOXIMETRO">PULSIOXIMETRO</option>
                        <option value="REGULADOR DE OXIGENO CON FLUJÓMETRO">REGULADOR DE OXIGENO CON FLUJÓMETRO</option>
                        <option value="SUCCIONADOR">SUCCIONADOR</option>
                        <option value="TENSIOMETRO ANALOGICO / ESFIGMOMANOMETRO">TENSIOMETRO ANALOGICO / ESFIGMOMANOMETRO</option>
                        <option value="TENSIOMETRO DIGITAL">TENSIOMETRO DIGITAL</option>
                        <option value="TERMOHIGRÓMETRO">TERMOHIGRÓMETRO</option>
                        <option value="TERMOHIGROMETRO ANALÓGICO">TERMOHIGROMETRO ANALÓGICO</option>
                        <option value="TERMOHIGROMETRO DIGITAL">TERMOHIGROMETRO DIGITAL</option>
                        <option value="TERMOMETRO DIGITAL">TERMOMETRO DIGITAL</option>
                        <option value="TERMOMETRO INFRARROJO">TERMOMETRO INFRARROJO</option>
                        <option value="TERMOMETRO DE PUNZÓN">TERMOMETRO DE PUNZÓN</option>
                        <option value="ULTRASONIDO DE TERAPIA">ULTRASONIDO DE TERAPIA</option>
                        <option value="VENTILADOR MECÁNICO">VENTILADOR MECÁNICO</option>
                        <option value="DENSIMETRO">DENSIMETRO</option>
                        <option value="DATALOGGER DE TEMPERATURA Y HUMEDAD">DATALOGGER DE TEMPERATURA Y HUMEDAD</option>
                        <option value="REGULADOR DE OXÍGENO">REGULADOR DE OXÍGENO</option>
                    </datalist>
                    <label>Marca</label>
                    <input type="text" onChange={handleInputChange} id="marca" name="marca" list="brands" required />
                    <datalist id="brands">
                        <option>geotec</option>
                    </datalist>
                    <label>Modelo</label>
                    <input type="text" onChange={handleInputChange} id="model" name="model" list="Colors" required />
                    <button className="btn btn-primary btn-xl center-div-botton" type="submit">Registrar</button>
                </form>
            </div>
        </Fragment>
    );
}
export default EquipRegistrationPop