import React, { Fragment, useState } from "react";

export function MenuLateralInge({ info, goStadistics, addCotiz, addEquip, goTogestionCotiz, 
    goToManegementEquipment, goAprovedCotiz, goAssigned, goListInform}) {
    //const infoUser = Object.values(info)[0]
    const contact = "contactenosssssss";
    //const userNameToShow = infoUser.userName + "....";
    var userNameToShow = info;
    userNameToShow = "santiago";

    const usuariosImg = process.env.PUBLIC_URL + '/bases images/adduserIconWhite.png';

    //eventos visuales

    const [showSubmenuCotizaciones, setShowSubmenuContizaciones] = useState(false);
    const [showSubmenuEquipos, setShowSubmenuEquipos] = useState(false);
    const [showSubmenuInformes, setShowSubmenuInformes] = useState(false);

    const toggleSubmenuCotizaciones = () => {
        setShowSubmenuContizaciones(!showSubmenuCotizaciones);
        //setShowSubmenuUsuarios(false)
    };

    const toggleSubmenuEquipos = () => {
        setShowSubmenuEquipos(!showSubmenuEquipos);
        //setShowSubmenuContizaciones(false);
    }

    const toggleSubmenuInformes = () => {
        setShowSubmenuInformes(!showSubmenuInformes);
        //setShowSubmenuContizaciones(false);
    }

    const clickToGoStadistics = (event) => {
        event.preventDefault()
        goStadistics()
    }

    const clickToGoCreateCotiz = (event) => {
        event.preventDefault()
        addCotiz()
    }

    const clickToAddEquip = (event) => {
        //event.preventDefault()
        addEquip()
    }

    const clickTogoTogestionCotiz = (event) => {
        event.preventDefault()
        goTogestionCotiz()
    }

    const clickTogoToManagementEquipment = (event) => {
        event.preventDefault()
        goToManegementEquipment()
    }

    const clickToGoAprovedCotiz = (event) => {
        event.preventDefault()
        goAprovedCotiz()
    }

    const clickToGoAssignedCotiz = (event) => {
        event.preventDefault()
        goAssigned()
    }

    const clickToGoListInform = (event) => {
        event.preventDefault()
        goListInform()
    }
    //si se borran los formularios se debe borrar href="#"
    return (
        <div className="menu-lateral" >
            <ul style={{ borderTop: '70px solid white' }}>
                <li className={`menu-item`}>
                    <a href="#" onClick={clickToGoStadistics}>Inicio</a>
                </li>
                
                <li className={`menu-item ${showSubmenuEquipos ? 'active' : ''}`}>
                    <a href="#" onClick={toggleSubmenuEquipos}>Equipos</a>
                </li>
                {showSubmenuEquipos && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickToAddEquip}>- Crear Equipo</a>
                    </li>
                )}
                {showSubmenuEquipos && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickTogoToManagementEquipment}>- Listado de equipos</a>
                    </li>
                )}
               <li className={`menu-item ${showSubmenuInformes ? 'active' : ''}`}>
                    <a href="#" onClick={toggleSubmenuInformes}>Informes</a>
                </li>
                {showSubmenuInformes && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickToGoAssignedCotiz}>- Trabajos asignados</a>
                    </li>
                )}
                {showSubmenuInformes && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickToGoListInform}>- Listado de informes</a>
                    </li>
                )}
                <li className={`menu-item`}>
                    <a href="#">Contacto</a>
                </li>
            </ul>
        </div>
    );
}