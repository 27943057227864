import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

export function AssignedCotiz({ goAddPDF, urldb, iduser }) {

    const [cotizaciones, setCotizaciones] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(urldb + '/CotizacionDeInge/' + iduser)
            .then((response) => {
                setCotizaciones(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener cotizaciones:', error);
            });
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...

        if (orderBy) {
            cotizaciones.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);



    const clickNavAddpdf = (event) => {
        event.preventDefault()
        goAddPDF(event.target.value);
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };


    return (
        <Fragment>
            <h1>Cotizaciones asignadas</h1>
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                        <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." />
                        <button style={{ marginRight: "10px" }} id="search-button" className='btn-icono'>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select>
                            <option value="codigo">Código</option>
                            <option value="entidad">Entidad Cliente</option>
                            <option value="precio">Precio Total</option>
                            <option value="correo">Correo de Contacto</option>
                            <option value="estado">Estado</option>
                            <option value="fecha">Fecha de Generación</option>
                            <option value="ingeniero">Ingeniero Asignado</option>
                        </select>
                    </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('idcotizacion')}
                                className={orderBy === 'idcotizacion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Código
                            </th>
                            <th style={{ whiteSpace: "nowrap" }} onClick={() => handleHeaderClick('entidad_asignada')}
                                className={orderBy === 'entidad_asignada' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Entidad Cliente
                            </th>
                            <th onClick={() => handleHeaderClick('valorttotal')}
                                className={orderBy === 'valorttotal' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Precio Total
                            </th>
                            <th onClick={() => handleHeaderClick('contactoentidad')}
                                className={orderBy === 'contactoentidad' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Correo de contacto
                            </th>
                            <th onClick={() => handleHeaderClick('estado')}
                                className={orderBy === 'estado' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Estado
                            </th>
                            <th onClick={() => handleHeaderClick('fecha')}
                                className={orderBy === 'fecha' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Fecha de Generación
                            </th>
                            <th onClick={() => handleHeaderClick('ingenieros_asignados')}
                                className={orderBy === 'ingenieros_asignados' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Ingeniero Asignado
                            </th>
                            <th>Informes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cotizaciones.map((cotizacion, index) => (
                            <tr key={index}>
                                <td>{cotizacion.idcotizacion}</td>
                                <td>{cotizacion.entidad_asignada}</td>
                                <td>{cotizacion.valorttotal}</td>
                                <td>{cotizacion.contactoentidad}</td>
                                <td>{cotizacion.estado}</td>
                                <td>{format(new Date(cotizacion.fecha), 'dd/MM/yyyy')}</td>
                                <td>{cotizacion.ingenieros_asignados}</td>
                                <td><button onClick={clickNavAddpdf} className='btn' value={cotizacion.idcotizacion}>Agregar</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </Fragment>
    )
}
export default AssignedCotiz