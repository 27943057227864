import React, { Fragment, useEffect, useState } from "react";

export function MenuSuperUser({ info, gohome }) {
    //const infoUser = Object.values(info)[0]
    const [showFloatingButton, setShowFloatingButton] = useState(false);
    //const userNameToShow = infoUser.userName + "....";
    var userNameToShow = info.nameuser;
    var rolToShow = info.rol;
    //userNameToShow = "SantiagoGonzalez";

    const usuariosImg = process.env.PUBLIC_URL + '/bases images/adduserIconWhite.png';

    const clickToGoHome = (event) => {
        event.preventDefault()
        gohome()
    }

    const toggleFloatingButton = () => {
        setShowFloatingButton(!showFloatingButton);
    }

    useEffect(() => {
        if (!userNameToShow || !info) {
            gohome();
        }
    }, [userNameToShow, info, gohome]);

    return (
        <Fragment>
            <header style={{backgroundColor: 'white', borderBottom: '1px solid grey', position: 'fixed', width: '100%' }}>
                <nav style={{backgroundColor: 'white'}}>
                    <div className="logo">
                        <img src="bases images\LN.png" alt="Logo de la Empresa" />
                    </div>
                    <div className="menu" style={{backgroundColor: 'white'}}>
                        <ul>
                            {/*<li><a href="#" onClick={clickToGoCreateUser}>Crear usuarios</a></li>
                            <li><a href="#" onClick={clickToAddEntitie} >Crear entidad medica</a></li>
                            <li><a href="#" onClick={clickToAddEquip}>Crear equipos</a></li>
    <li><a href="#" onClick={clickTogoTogestionCotiz}>Gestionar Cotizaciones</a></li>*/}
                            <li><a href="#" style={{color: 'black'}}>Medimaint Manager </a></li>
                            <li><a href="#" style={{color: 'black'}}>{rolToShow}</a></li>
                        </ul>
                    </div>
                    <div className="user">
                        <button style={{ marginRight: '8px', textAlign: 'center', borderRadius: '4px' }} onClick={toggleFloatingButton}>{userNameToShow} <img src={usuariosImg}
                            alt="Icono" style={{ marginTop: '8px', width: '40px', height: '40px' }} />
                        </button>
                        {showFloatingButton && (
                            <div>
                                <button className="btn-floating-exit" style={{ width: '60%', backgroundColor: '#A24343' }} onClick={clickToGoHome}>
                                    Cerrar sesion
                                    {/* Agrega aquí el contenido de tu botón flotante <img src="bases images/adduserIcon.png" alt="Agregar" />whiteSpace: 'nowrap',*/ }
                                </button>
                            </div>
                        )}
                    </div>
                </nav>
            </header>
        </Fragment>
    );
}