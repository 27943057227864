import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

export function GestionUsers({ goToCreateUsers, goEditUser, message, urldb }) {

    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    const [usuarios, setUsuarios] = useState([]);

    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('idcotizacion');

    const clickSearch = async () => {
        //console.log('Valor de búsqueda:', searchValue);
        //console.log('Valor seleccionado:', selectedOption);
        const columna = selectedOption;
        var valor = searchValue;
        /*eliminar este if si de formatea en api
        if(selectedOption === "fecha"){
            valor = valor + 'T05:00:00.000Z'
            console.log('convert')
        }*/
        try {
            const response = await axios.get(urldb + `/usuarios?columna=${columna}&valor=${valor}`);
            setUsuarios(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los resultados:', error);
        }
    };

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(urldb + '/usuarios')
            .then((response) => {
                setUsuarios(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener usuarios:', error);
            });

    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...

        if (orderBy) {
            usuarios.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);



    const clickUserNavCreate = (event) => {
        event.preventDefault()
        goToCreateUsers()
    }

    const clickNavEditUser = (event) => {
        const dat = event.target.value;
        goEditUser(dat)
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };


    return (
        <Fragment>
            <h1>Listado de usuarios</h1>
            {message && message.includes("Usuario ") && (
                <p className='message-parr'>{message}</p>
            )}
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                    <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} />
                        <button style={{ marginRight: "10px" }} id="search-button"
                            className='btn-icono' onClick={clickSearch}>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="email">Correo</option>
                            <option value="telefono">Telefono</option>
                            <option value="rol">Rol</option>
                            <option value="nombre_usuario">nombre</option>
                        </select>
                    </div>
                    <div className="headerMenu">
                        <h1></h1>
                        <button className="btn btn-primary" onClick={clickUserNavCreate}>Crear usuario</button>
                    </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('nombre_usuario')}
                                className={orderBy === 'nombre_usuario' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Nombre
                            </th>
                            <th onClick={() => handleHeaderClick('rol')}
                                className={orderBy === 'rol' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Rol
                            </th>
                            <th onClick={() => handleHeaderClick('email')}
                                className={orderBy === 'email' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Correo
                            </th>
                            <th onClick={() => handleHeaderClick('telefono')}
                                className={orderBy === 'telefono' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Telefono
                            </th>

                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usuarios.map((usuario, index) => (
                            <tr key={usuario.idusuario}>
                                <td>{usuario.nombre_usuario}</td>
                                <td>{usuario.rol}</td>
                                <td>{usuario.email}</td>
                                <td>{usuario.telefono}</td>
                                <td>
                                    <button className='btn' onClick={clickNavEditUser} value={usuario.idusuario}>
                                        Editar
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

        </Fragment>
    )
}
export default GestionUsers