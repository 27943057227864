import React, { useState, Fragment } from 'react';

export function Login({ TodoClick }) {

    const [datos, setDatos] = useState({
        email: '',
        password: ''
    })

    const [error, setError] = useState('');

    const handleInputChange = (event) => {
        console.log(event.target.name)
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    const enviarDatos = (event) => {
        event.preventDefault()
        TodoClick(datos)
        var loginFallido = TodoClick(datos)
        if (!loginFallido) {
            setError('Credenciales incorrectas. Por favor, inténtalo de nuevo.');
        } else {
            setError('');
            // Inicio de sesión exitoso, redirecciona o realiza las acciones necesarias
        }
    }

    return (
        <Fragment>
            <section className="page-section" id="contact">
                <div className="container-login">
                    <div className="container-login container-login-lavel-two border-login">
                        <img className="center-img" src="bases images/LN.png" alt="..." />
                        <div className="divider-custom divider-custom-login">
                            <div className="divider-custom-line"></div>
                        </div>
                        <div className="divider-custom-line"></div>
                        <div className="row">
                            <div className="col-lg-8 mx-auto">
                                <form id="loginForm" name="sentMessage" novalidate="novalidate" onSubmit={enviarDatos}>
                                    <div style={{ width: "85%", marginLeft: "20px" }}>
                                        <div style={{ border: "none", padding: "0", fontSize: "20px" }} className="form-group floating-label-form-group floating-label-form-group-login controls mb-4 pb-0" >
                                            <label>nombre de usuario</label>
                                            <input style={{height:"25px", fontSize: "20px" }} className="form-control margin-left-forms-inputs" onChange={handleInputChange} name="email" id="email" type="email" placeholder="Nombre de usuario" required="required" data-validation-required-message="Please enter your User Name." />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div style={{ width: "85%", marginLeft: "20px" }}>
                                        <div style={{ border: "none", padding: "0", fontSize: "20px" }} className="form-group floating-label-form-group floating-label-form-group-login controls mb-4 pb-0">
                                            <label>Contraseña</label>
                                            <input style={{ height:"25px", fontSize: "20px" }} className="form-control margin-left-forms-inputs" onChange={handleInputChange} name="password" id="password" type="password" placeholder="Contraseña" required="required" data-validation-required-message="Please enter your password." />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="center-div-botton">
                                        <button style={{ textAlign: 'center' }}className="btn btn-primary btn-xl center-div-botton" id="sendMessageButton" type="submit">Entrar</button>
                                        {/*<button className="btn btn-primary btn-xl center-div-botton" id="sendMessageButton" type="submit" onclick="location.href='userRegister.html'">Register</button>*/}
                                    </div>
                                </form>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <p style={{ color: 'red' }}>{error}</p>
                                </div>
                                <div className="margin-register-bottom">
                                    <div className="center-div-botton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default Login