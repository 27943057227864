import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

export function AssingEngi({ cothospital, urldb, detal }) {

  const [assignmentsEngi, setAssignments] = useState([]);
  const [changes, setChanges] = useState([]);
  const [componentKey, setComponentKey] = useState(0);
  const [message, setmessage] = useState('')
  //const [cothospital, setctohsopital] = useState(cothospital)

  const fetchEngie = () => {
    // Realiza una solicitud GET al servidor para obtener cotizaciones actualizadas
    axios.get(urldb + '/usuarioAsignados/' + cothospital.idcotizacion)
      .then((response) => {
        setAssignments(response.data); // Actualiza el estado de cotizaciones con los datos más recientes
      })
      .catch((error) => {
        console.error('Error al obtener cotizaciones:', error);
      });
  };

  const updateAssig = async (idCotizacion, idusuario, value) => {
    const datosCotiz = {
      idcotizacion: idCotizacion,
      idusuario: idusuario
    }
    try {
      if (value) {
        console.log('intento de creacion')
        const respuesta = await axios.post(urldb + '/asignarCot', datosCotiz, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } else {
        console.log('intento de borrado')
        const response = await fetch(urldb + '/borrarAsignacion/' + idusuario + '/' + idCotizacion, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error('Error al eliminar detalle');
        }
        const data = await response.json();
      }
    } catch (error) {
      alert('error al cambiar estado de la asignacion');
      //throw error;
    }
  };

  useEffect(() => {
    fetchEngie()
    setChanges([])
  }, [])

  const handleCheckboxChange = (event, engineer) => {
    const { checked } = event.target;
    const updatedAssignments = assignmentsEngi.map((item) =>
      item.idusuario === engineer.idusuario ? { ...item, hayasignacion: checked } : item
    );
    setAssignments(updatedAssignments);
    //se guardan los cambios que se van a hacer en la base de datos, los necesarios
    //si no hubo cambios en comparacion al incio, no se añaden y se anula el anterior que se hizo
    const existingChangeIndex = changes.findIndex(
      (change) => change.idusuario === engineer.idusuario
    );

    if (existingChangeIndex !== -1) {
      const updatedChanges = [...changes];
      if (updatedChanges[existingChangeIndex].checked !== checked) {
        //updatedChanges[existingChangeIndex].checked = checked;
        const filteredChanges = updatedChanges.filter(
          (change) => change.idusuario !== engineer.idusuario
        );
        setChanges(filteredChanges);
        //setChanges(updatedChanges);
      }
    } else {
      const updatedChanges = [
        ...changes,
        { idusuario: engineer.idusuario, checked }
      ];
      setChanges(updatedChanges);
    }

    if (checked) {
      setmessage("Se asigno el usuario: " + engineer.nombre_usuario + "\n")
    } else {
      setmessage("Se elimino la asingacion del usuario: " + engineer.nombre_usuario + "\n")
    }
  };

  const sendChanges = () => {
    //window.location.reload();
    //fetchEngie()
    //setComponentKey(prevKey => prevKey + 1);
    changes.forEach((change, index) => {
      updateAssig(cothospital.idcotizacion, change.idusuario, change.checked)
    });
    setChanges([])
    setmessage('')
  };

  return (
    <Fragment key={componentKey}>
      <div>
        <h2>Asignacion de ingenieros</h2>
        <div style={{ width: "900px", fontSize: "20px" }} className='shadow-div'>
          <h2>Datos de cotización</h2>
          <div className='formulario-doble'>
            <div style={{ width: "400px", fontSize: "20px", }} className='shadow-div columna'>
              <div style={{ display: 'flex', alignItems: 'center' }}><h3 style={{ paddingRight: "10px" }}>Entidad: </h3><p>{cothospital.nombrecomercial} </p></div>
              <div style={{ display: 'flex', alignItems: 'center' }}><h3 style={{ paddingRight: "10px" }}>Fecha: </h3><p>{format(new Date(cothospital.fecha), 'dd/MM/yyyy')}</p></div>
              <div style={{ display: 'flex', alignItems: 'center' }}><h3 style={{ paddingRight: "10px" }}>Ciudad: </h3><p>{cothospital.nombre_lugar}</p></div>
              <div style={{ display: 'flex', alignItems: 'center' }}><h3 style={{ paddingRight: "10px" }}>Dirección: </h3><p>{cothospital.direccion}</p></div>
            </div>
            <div style={{ width: "400px", fontSize: "20px", }} className='shadow-div columna'>
              <h3 style={{ paddingRight: "10px" }}>Listado de equipos</h3>
              {detal.map((detalle, index) => (
                <div style={{ display: 'flex', alignItems: 'center' }}><h3 style={{ paddingRight: "10px" }}>- </h3><p> {detalle.tipoequipo} ({detalle.marca}) </p></div>
              ))}
            </div>
          </div>
          <h2>Ingenieros disponibles</h2>
          <div style={{ width: "600px", fontSize: "20px" }} className='shadow-div'>
            <table style={{ padding: "10px" }}>
              <thead>
                <tr>
                  <th style={{ paddingRight: "60px" }}>Ingeniero</th>
                  <th style={{ whiteSpace: 'nowrap' }}>Estado Asignación</th>
                </tr>
              </thead>
              <tbody>
                {assignmentsEngi.map((engineer) => (
                  <tr key={engineer.idusuario}>
                    <td style={{ paddingRight: "60px", whiteSpace: 'nowrap' }}>{engineer.nombre_usuario}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={engineer.hayasignacion}
                        onChange={(e) => handleCheckboxChange(e, engineer)}
                      />
                      {engineer.hayasignacion ? (
                        <span style={{ color: 'green' }}>Asignado</span>
                      ) : (
                        <span style={{ color: 'red' }}>No asignado</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <p style={{ color: 'green', marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}>{message}</p>
          
          {changes.length !== 0 && (
            <button style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '5px', marginTop: '10' }}
              className="btn btn-primary" onClick={sendChanges}>
              Guardar cambios<img src="bases images/saveicon.png" alt="Agregar" />
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default AssingEngi;
