import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

export function GestionCotiz({ goToCreateCotiz, message, goToEditCotiz, urldb }) {

    const [cotizaciones, setCotizaciones] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });
    const [selectedEstado, setSelectedEstado] = useState('P');
    const [componentKey, setComponentKey] = useState(0);
    const [mensaje, setmessage] = useState(message);

    const [changes, setChanges] = useState([]);

    const fetchCotizaciones = () => {
        // Realiza una solicitud GET al servidor para obtener cotizaciones actualizadas
        axios.get(urldb + '/cotizaciones')
            .then((response) => {
                setCotizaciones(response.data); // Actualiza el estado de cotizaciones con los datos más recientes
            })
            .catch((error) => {
                console.error('Error al obtener cotizaciones:', error);
            });
    };

    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('idcotizacion');

    const clickSearch = async () => {
        //console.log('Valor de búsqueda:', searchValue);
        //console.log('Valor seleccionado:', selectedOption);
        const columna = selectedOption;
        var valor = searchValue;
        /*eliminar este if si de formatea en api
        if(selectedOption === "fecha"){
            valor = valor + 'T05:00:00.000Z'
            console.log('convert')
        }*/
        try {
            const response = await axios.get(urldb + `/cotizaciones?columna=${columna}&valor=${valor}`);
            setCotizaciones(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los resultados:', error);
        }
    };

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        fetchCotizaciones()
        setChanges([])
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...

        if (orderBy) {
            cotizaciones.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);

    const handleEstadoChange = (event, idCotizacion) => {
        setSelectedEstado(event.target.value);
        const nuevoEstado = event.target.value;
        console.log('Actualizado estado: ' + idCotizacion + ", " + nuevoEstado)
        const updatedAssignments = cotizaciones.map((item) =>
            item.idcotizacion === idCotizacion ? { ...item, estado: nuevoEstado } : item
        );
        setCotizaciones(updatedAssignments);

        const existingChangeIndex = changes.findIndex(
            (change) => change.idcotizacion === idCotizacion
        );

        if (existingChangeIndex !== -1) {
            const updatedChanges = [...changes];
            if (updatedChanges[existingChangeIndex].nuevoEstado !== nuevoEstado) {
                updatedChanges[existingChangeIndex].nuevoEstado = nuevoEstado;
                setChanges(updatedChanges);
            }
        } else {
            const updatedChanges = [
                ...changes,
                { idcotizacion: idCotizacion, nuevoEstado }
            ];
            setChanges(updatedChanges);
        }
        // Llama a la función updateEstado con el ID de cotización y el nuevo estado
        //updateEstado(idCotizacion, nuevoEstado)
        setmessage("Estado de Cotización " + idCotizacion + " actualizado")
    };

    const saveChanges = () => {
        //fetchCotizaciones();
        //setComponentKey(prevKey => prevKey + 1);
        changes.forEach((change, index) => {
            console.log(change)
            updateEstado(change.idcotizacion, change.nuevoEstado)
        });
        setChanges([])
        setmessage('')
    };

    const updateEstado = async (idCotizacion, nuevoEstado) => {
        const datosCotiz = {
            idcotizacion: idCotizacion,
            estado: nuevoEstado
        }
        try {
            const respuesta = await axios.put(urldb + '/cambiarEstadoCot', datosCotiz, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Estado actualizado:', respuesta.data);
        } catch (error) {
            console.error('Error al actualizar estado:', error);
            throw error;
        }
    };

    const clickCotizaNavCreate = (event) => {
        event.preventDefault()
        console.log("navigateCreate")
        goToCreateCotiz()
    }

    const clickToNavEdit = (event) => {
        const dat = event.target.value;
        goToEditCotiz(dat)
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };


    return (
        <Fragment key={componentKey}>
            <h1>Gestión de cotizaciones</h1>
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                        <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} />
                        <button style={{ marginRight: "10px" }} id="search-button"
                            className='btn-icono' onClick={clickSearch}>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="idcotizacion">Código</option>
                            <option value="nombreComercial">Entidad Cliente</option>
                            <option value="valorttotal">Precio Total</option>
                            <option value="contactoentidad">Correo de Contacto</option>
                            <option value="fecha">Fecha de Generación</option>
                            <option value="nombre_usuario">Ingeniero Asignado</option>
                        </select>
                    </div>
                    <div className="headerMenu">
                        <button className="btn btn-primary" onClick={clickCotizaNavCreate}>Generar Nueva Cotización</button>
                        {changes.length !== 0 && (
                            <button style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '5px' }} className="btn btn-primary"
                                onClick={saveChanges}>Guardar cambios<img src="bases images/saveicon.png" alt="Agregar" /></button>
                        )}
                    </div>
                    {mensaje && mensaje.includes("Cotización") && (
                        <p className='message-parr'>{mensaje}</p>
                    )}
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('idcotizacion')}
                                className={orderBy === 'idcotizacion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Código
                            </th>
                            <th style={{ whiteSpace: "nowrap" }} onClick={() => handleHeaderClick('nombre_entidad_medica')}
                                className={orderBy === 'nombre_entidad_medica' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Entidad Cliente
                            </th>
                            <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('valorttotal')}
                                className={orderBy === 'valorttotal' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Precio Total
                            </th>
                            <th onClick={() => handleHeaderClick('contactoentidad')}
                                className={orderBy === 'contactoentidad' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Correo de contacto
                            </th>
                            <th onClick={() => handleHeaderClick('estado')}
                                className={orderBy === 'estado' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Estado
                            </th>
                            <th onClick={() => handleHeaderClick('fecha')}
                                className={orderBy === 'fecha' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Fecha de Generación
                            </th>
                            <th onClick={() => handleHeaderClick('nombre_usuario')}
                                className={orderBy === 'nombre_usuario' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Ingeniero Asignado
                            </th>
                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cotizaciones.map((cotizacion, index) => {
                            let fechaOriginal = cotizacion.fecha;
                            let partesFecha = fechaOriginal.split('/');
                            let fechaParseada = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
                            let dia = fechaParseada.getDate().toString().padStart(2, '0');
                            let mes = (fechaParseada.getMonth() + 1).toString().padStart(2, '0');
                            let anio = fechaParseada.getFullYear();
                            let fechaFormateada = `${dia}-${mes}-${anio}`;
                            return (
                                <tr key={index}>
                                    <td>{cotizacion.idcotizacion}</td>
                                    <td>{cotizacion.nombre_entidad_medica}</td>
                                    <td>$ {cotizacion.valorttotal.toLocaleString('es-ES')}</td>
                                    <td>{cotizacion.contactoentidad}</td>
                                    <td>
                                        <select value={cotizacion.estado}
                                            onChange={(event) => handleEstadoChange(event, cotizacion.idcotizacion)}>
                                            <option value="P">Pendiente</option>
                                            <option value="A">Aprobado</option>
                                            <option value="C">Confirmado</option>
                                            <option value="X">Cancelado</option>
                                        </select>
                                    </td>
                                    <td>{fechaFormateada}</td>
                                    <td>{cotizacion.nombre_usuario}</td>
                                    <td>
                                        <button onClick={clickToNavEdit} className='btn' value={cotizacion.idcotizacion}>
                                            Editar
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </section>

        </Fragment>
    )
}
export default GestionCotiz