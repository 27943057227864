import React, { useState, useEffect, Fragment } from 'react';

export function UserRegister({ TodoClick, showEdit, user, sendEditedUser }) {

    //hay que arreglar el Rol
    const [datos, setDatos] = useState({
        idusuario: '',
        names: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        repeatPassword: '',
        rol: '',
    })

    useEffect(() => {
        if (showEdit && user) {
            //console.log('miau')
            setDatos({
                idusuario: user.idusuario,
                names: user.nombre_usuario,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.telefono,
                password: user.contraseina,
                repeatPassword: user.contraseina,
                rol: user.rol
            });
        }
    }, [showEdit, user]);

    const handleInputChange = (event) => {
        //console.log(event.target.name)
        // console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    /*
    const handleSelectChange = (event) => {
        setDatos({
            ...datos,
            rol: event.target.value, // Almacena el valor seleccionado del rol
        });
    };
    */
    const enviarDatos = (event) => {
        if (showEdit) {
            event.preventDefault()
            sendEditedUser(datos)
        } else {
            event.preventDefault()
            TodoClick(datos)
        }
    }

    return (
        <Fragment>
            <div className="formulario-container" style={{backgroundColor: '#E3ECEE'}}>
                {showEdit && (
                    <h1>Editar usuario</h1>
                )}
                {!showEdit && (
                    <h1>Registro de nuevo usuario</h1>
                )}
                <div className='shadow-div'>
                    <form id="registration-form" name="sentMessage" novalidate="novalidate" onSubmit={enviarDatos}>

                        <label>Nombre</label>
                        <input
                            onChange={handleInputChange}
                            id="names" name='names'
                            type="text"
                            required="required"
                            data-validation-required-message="Please enter your names."
                            value={datos.names}
                        />
                        <p class="help-block text-danger"></p>

                        {/* 
                            <div class="control-group">
                                <div class="form-group floating-label-form-group floating-label-form-group-login controls mb-4 pb-0 margin-register-fields">
                                    <label>Surnames</label>
                                    <input class="form-control margin-left-forms-inputs" onChange={handleInputChange} id="lastName" name='lastName' type="text" placeholder="Apellidos" required="required" data-validation-required-message="Please enter your Surnames." />
                                    <p class="help-block text-danger"></p>
                                </div>
                            </div>
                            */}
                        <label>Correo electronico</label>
                        <input
                            onChange={handleInputChange}
                            id="email"
                            name='email'
                            type="email"
                            required="required"
                            data-validation-required-message="Please enter your email address."
                            value={datos.email}
                        />
                        <p class="help-block text-danger"></p>


                        <label>Numero de telefono</label>
                        <input
                            onChange={handleInputChange}
                            id="phoneNumber" name='phoneNumber'
                            type="number"
                            required="required"
                            data-validation-required-message="Please enter your Phone Number."
                            value={datos.phoneNumber}
                        />
                        <p class="help-block text-danger"></p>

                        <label>Rol</label>
                        <input type="text"
                            onChange={handleInputChange}
                            id="rol"
                            name="rol"
                            list="Colors"
                            value={datos.rol}
                            style={{ width: '100%' }}
                        />
                        <datalist id="Colors">
                            <option value="Auxiliar admin">Auxiliar admin</option>
                            <option value="Director">Director</option>
                            <option value="Ingeniero">Ingeniero</option>
                        </datalist>
                        <p class="help-block text-danger"></p>
                        <label>Contraseña</label>
                        <input
                            onChange={handleInputChange}
                            id="password"
                            name='password'
                            type="password"
                            required="required"
                            data-validation-required-message="Please enter your Password."
                            value={datos.password} />
                        <p class="help-block text-danger"></p>

                        <label>Repita la Contraseña</label>
                        <input
                            onChange={handleInputChange}
                            id="repeatPassword"
                            name='repeatPassword'
                            type="password"
                            required="required"
                            data-validation-required-message="Please Repeat your Password."
                            value={datos.repeatPassword}
                        />
                        <p class="help-block text-danger"></p>


                        <div class="center-div-botton">
                            {showEdit && (
                                <button className="btn btn-primary btn-xl center-div-botton" type="submit">Editar</button>
                            )}
                            {!showEdit && (
                                <button className="btn btn-primary btn-xl center-div-botton" style={{ whiteSpace: 'nowrap', width: '45%' }} type="submit">Registrar usuario</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Fragment >
    );
}
export default UserRegister