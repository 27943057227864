
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';

//componentes
import { Login } from './components/Login';
import { MenuSuperUser } from './components/MenuSuperUser';
import { MenuLateralSuper } from './components/MenuLateralSuper';
import { MenuLateralAxuliarAdmin } from './components/MenuLateralAxuliarAdmin';
import { MenuLateralInge } from './components/MenuLateralInge';
import { MenuLateralDir } from './components/MenuLateralDir'
import { GestionCotiz } from './components/GestionCotiz';
import { AprovedCotiz } from './components/AprovedCotiz';
import { GestionUsers } from './components/GestionUsers';
import { ManagementEquipment } from './components/ManagementEquipment'
import { ManagementEntitites } from './components/ManagementEntities'
import { Popup } from './components/Popup';
import { Popup2 } from './components/Popup2';
import { EntitieRegistration } from './components/EntitieRegistration';
import { EquipRegistrationPop } from './components/EquipRegistrationPop';
import { UserRegister } from './components/UserRegister';
import { Stadistics } from './components/Stadistics';
import { CreateCotiz } from './components/CreateCotiz';
import PdfQuote, { PDFQuote } from './components/PdfGenerator';
import InformGenerator, { InformGene } from './components/InformGenerator';
import { AssignedCotiz } from './components/AssignedCotiz';
import { SaveInform } from './components/SaveInform';
import { AssingEngi } from './components/AssingEngi'
import { InformList } from './components/InformList'


export function App() {
    const navigate = useNavigate();

    //seccion para guardar variable globales
    //const baseUrlDB = 'http://192.168.56.1:3001';
    const baseUrlDB = 'https://medimaint-backend-api-production.up.railway.app'
    const [sessionInfo, setInfoSession] = useState({
        nameuser: '',
        firstname: '',
        lastname: '',
        keyuser: '',
        rol: '',
    });

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [cotizInfo, setCotizinfo] = useState({
        idCot: '',
        equipos: [],
        entidadSeleccinada: '',
        IVA: 0,
        consum: 0,
        total: 0,
        fecha: '',
        fechaFin: '',
    })

    const [lastMessage, setLastMessage] = useState(null)

    //  constantes para editar despues
    const [userToEdit, setUserToEdit] = useState(null)
    const [cotizToEdit, setCotizToEdit] = useState(null)
    const [lastCotiz, setLastCotiz] = useState(null)
    const [lastEquip, setLastEquip] = useState(null)
    const [detalToEdit, setDetalToEdit] = useState([])

    const [lastInfor, setLastInfor] = useState(null)
    const [detalnfor, setDetalInfor] = useState([])

    //hay que arreglar la seleccion de rol

    //poup 1 entidad medica
    const [isPopupOpenEnt, setIsPopupOpen] = useState(false);

    const openPopupAddEntitie = () => {
        setIsPopupOpen(true);
    };

    const closePopupEnt = () => {
        setIsPopupOpen(false);
    };

    //popup 2 equipo medico

    const [isPopupOpenEqu, setIsPopupOpenEqu] = useState(false);

    const openPopupEqu = () => {
        setIsPopupOpenEqu(true);
    };

    const closePopupEqu = () => {
        setIsPopupOpenEqu(false);
    };

    //eventos para enviar
    //Login

    const [usuarios, setUsuarios] = useState([]);

    //respuestas informe
    const [PruebasCual, setPruebasCual] = useState([])
    const [PruebasCuan, setPruebasCuan] = useState([])
    const [MantenPrev, setMantenPrev] = useState([])
    const [testAceptacion, setTestAceptacion] = useState([])
    const [datosInforme, setDatosInforme] = useState()

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(baseUrlDB + '/usuarios')
            .then((response) => {
                setUsuarios(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener usuarios:', error);
            });
    }, []);

    const clickToLogIn = (datos) => {
        const email = datos.email;
        const password = datos.password;

        // Compara los datos de inicio de sesión con la lista de usuarios
        //console.log(usuarios)
        const usuarioEncontrado = usuarios.find((usuario) => {
            return usuario.email === email.replace(/['"]+/g, '') && usuario.contraseina === password.replace(/['"]+/g, '');
        });
        //console.log(usuarioEncontrado)

        if (usuarioEncontrado) {
            // Usuario encontrado, puedes realizar la navegación o acciones correspondientes
            //console.log('nameuser:' + usuarioEncontrado.nombre_usuario + ' rol:'
            //    + usuarioEncontrado.rol + ', keyuser:' + usuarioEncontrado.idusuario)
            setInfoSession({ nameuser: usuarioEncontrado.nombre_usuario, rol: usuarioEncontrado.rol, keyuser: usuarioEncontrado.idusuario })
            navigateToStadisctics();
            // Realiza las acciones necesarias aquí
            return true
        } else {
            // Usuario no encontrado, maneja el caso de inicio de sesión fallido
            console.log('Inicio de sesión fallido');
            // Realiza las acciones necesarias aquí
            return false
        }
    };
    ///////////////////////////////////////////////////////////////////////////
    /// REgistrar Usuario
    const ClickToAddUser = async (datos) => {
        const datosUsuario = {
            nombre: datos.names,
            email: datos.email,
            password: datos.password,
            telephone: datos.phoneNumber,
            rol: datos.rol
        };
        try {
            const respuesta = await axios.post(baseUrlDB + '/registrarUsuarios', datosUsuario, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setLastMessage('Usuario creado')
            navigateToGestionUsers();
            return respuesta.data;
        } catch (error) {
            console.error('Error al registrar usuario:', error);
            //throw error;
        }
    };

    //enviar datos de usuario para ser editados
    const ClickToEditUserNow = async (datos) => {
        const datosUsuario = {
            id: datos.idusuario,
            nombre: datos.names,
            email: datos.email,
            password: datos.password,
            telephone: datos.phoneNumber,
            rol: datos.rol
        };
        console.log(datosUsuario)
        try {
            const respuesta = await axios.post(baseUrlDB + '/editarUsuario', datosUsuario, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setLastMessage('Usuario ' + datos.names + ' Actualizado')
            navigateToGestionUsers();
            return respuesta.data;
        } catch (error) {
            console.error('Error al registrar usuario:', error);
            //throw error;
        }
    };


    //evento creacion cotizacion
    const ClickToCreateCotiz = async (datos, equipos, entidadSeleccinada, IVA, Consumibles, TOTAL) => {
        const datosCotizacion = {
            estado: 'P',
            contactoEntidad: datos.mail,
            telefono: datos.phone,
            fecha: getCurrentDate(),
            valorTotal: TOTAL,
            consumibles: Consumibles,
            aplicaIva: IVA,
            idEntidadMedica: entidadSeleccinada.identidadmedica
        };

        try {
            console.log(equipos)
            const respuesta = await axios.post(baseUrlDB + '/agregarCotizacion', datosCotizacion, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (equipos && Array.isArray(equipos)) {
                for (const equipo of equipos) {
                    const datosDetalle = {
                        idDetalle: 0,
                        tipo_servicio: equipo.tipoServ,
                        descripcion: equipo.descrip,
                        cantidad: equipo.cantidad,
                        valorUnitario: equipo.precio,
                        TipoEquipo: equipo.tipoEqu,
                        MarcaEquipo: equipo.marca
                    };
                    const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleCotizacion', datosDetalle, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
            setLastMessage('Cotización agregada')
            navigateToGestionCotiz();
            console.log('Cotizacion registrada:', respuesta.data);
        } catch (error) {
            console.error('Error al registrar cotizacion:', error);
            throw error;
        }
    }

    const ClickToUpdateCotiz = async (datos, equipos, entidadSeleccinada, IVA, Consumibles, TOTAL, deletedDetal) => {
        console.log('telefono:' + datos.phone,)
        const datosCotizacion = {
            idOldCotizacion: datos.idcotizacion,
            estado: 'P',
            contactoEntidad: datos.mail,
            telefono: datos.phone,
            valorTotal: TOTAL,
            consumibles: Consumibles,
            aplicaIva: IVA,
            idEntidadMedica: entidadSeleccinada.identidadmedica,
        };
        try {
            //console.log(equipos)
            const respuesta = await axios.post(baseUrlDB + '/EditarCotizacion', datosCotizacion, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (deletedDetal && Array.isArray(deletedDetal)) {
                for (const deta of deletedDetal) {
                    console.log(deta)
                    const response = await fetch(baseUrlDB + '/borrarDetalleCotizacion/' + deta, {
                        method: 'DELETE',
                    });
                    if (!response.ok) {
                        throw new Error('Error al eliminar detalle');
                    }
                    const data = await response.json();
                }
            }
            console.log('vieja cot: ' + datosCotizacion.idOldCotizacion)
            if (equipos && Array.isArray(equipos)) {
                for (const equipo of equipos) {
                    const datosDetalle = {
                        idDetalle: equipo.iddetalle,
                        tipo_servicio: equipo.tipoServ,
                        descripcion: equipo.descrip,
                        cantidad: equipo.cantidad,
                        valorUnitario: equipo.precio,
                        idOldCotizacion: datosCotizacion.idOldCotizacion,
                        TipoEquipo: equipo.tipoEqu,
                        MarcaEquipo: equipo.marca
                    };
                    if (!datosDetalle.idDetalle) {
                        const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleCotizacion', datosDetalle, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    } else {
                        const edicion = await axios.post(baseUrlDB + '/editarDetalleCotizacion', datosDetalle, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                    }
                }
            }
            console.log('Cotizacion actualizada:', respuesta.data);
            setLastMessage('Cotización actualizadada')
            navigateToGestionCotiz()
        } catch (error) {
            console.error('Error al actualizar cotizacion:', error);
            throw error;
        }

    }

    const ClickToCreateQuotePDF = async (datos, equipos, entidadSeleccinada, IVA, Consumibles, TOTAL, idcotizacion) => {
        /*console.log(datos)
        console.log(equipos)
        console.log(entidadSeleccinada)
        console.log(IVA)
        console.log(Consumibles)
        console.log(TOTAL)*/
        moment.locale('es');
        const fechaOriginal = cotizToEdit.fecha;
        const fechaOriginalMoment = moment(fechaOriginal);
        const fecha11MesesDespues = fechaOriginalMoment.clone().add(11, 'months');

        const fechaInicio = fechaOriginalMoment.format('D [de] MMMM [de] YYYY');

        const fechaConvertida = fecha11MesesDespues.format('D [de] MMMM [de] YYYY');

        console.log('inicio: ' + fechaInicio)
        console.log('fin: ' + fechaConvertida)

        setCotizinfo({
            equipos: equipos, entidadSeleccinada: entidadSeleccinada,
            IVA: IVA, consum: Consumibles, total: TOTAL, fecha: fechaInicio,
            fechaFin: fechaConvertida
        })
        navigatePDFQuote();
    }

    ///////////////// REgistrar Nueva entidad

    const AddNewEntitie = async (datos, selected) => {
        console.log('idlugar: ' + selected.idlugar)
        const datosEntidad = {
            nombreComercial: datos.nombreComercial,
            razonsocial: datos.razonSocial,
            NIT: datos.NIT,
            direccion: datos.direccion,
            correo: datos.correo,
            telefono: datos.telefono,
            ciudad: datos.ciudad,
            idlugar: selected.idlugar
        };
        try {
            const respuesta = await axios.post(baseUrlDB + '/registrarEntidad', datosEntidad, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            closePopupEnt();
            return respuesta.data;
        } catch (error) {
            console.error('Error al registrar entidad:', error);
            alert('El NIT esta en uso, o hay algun dato incorrecto');
        }
    }

    ////////////// REgistrar Equipo 
    const AddNewEquip = async (datos, selected) => {
        const datosEquipo = {
            idEntidad: selected.identidadmedica,
            tipeEquip: datos.tipeEquip,
            serial: datos.serial,
            marca: datos.marca,
            model: datos.model
        };
        try {
            const respuesta = await axios.post(baseUrlDB + '/registrarEquipo', datosEquipo, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Equipo registrada:', respuesta.data);
            setIsPopupOpenEqu(false)
            navigateToManagerEquipment();
            return respuesta.data;
        } catch (error) {
            console.error('Error al registrar equipo:', error);
            throw error;
        }
    }

    ///seccion de eventos de edicion

    const clickToGoEditUser = async (id) => {
        try {
            const userId = id;
            const linkService = baseUrlDB + '/usuarioPorId/' + userId;
            const response = await axios.get(linkService)
            const data = response.data;
            //console.log(data[0])
            setUserToEdit(data[0])
            navigateEditUser();
        } catch (error) {
            console.error('Error al encontrar el usuario:', error);
            throw error;
        }
    }

    const clickToGoEditCotiz = async (id) => {
        try {
            const cotizId = id;
            const linkService = baseUrlDB + '/CotizacionPorId/' + cotizId;
            const response = await axios.get(linkService)
            const dataCotiz = response.data;
            //console.log(dataCotiz[0])
            const linkServiceDetal = baseUrlDB + '/DetallesCotizacionPorId/' + cotizId;
            const responseDetal = await axios.get(linkServiceDetal)
            const dataDetal = responseDetal.data;
            //console.log(dataDetal)
            //====Asignacion de variables globales para se usadas luego====
            setCotizToEdit(dataCotiz[0])
            setDetalToEdit(dataDetal)
            navigateEditcotiz()
        } catch (error) {
            console.error('Error al encontrar cotizacion', error);
            throw error;
        }
    }

    const clickToGoAssignEngi = async (idCot) => {
        try {
            const cotizId = idCot;
            const linkService = baseUrlDB + '/CotizacionPorId/' + cotizId;
            const response = await axios.get(linkService)
            const dataCotiz = response.data;
            //====Asignacion de variables globales para se usadas luego====
            //setCotizToEdit(dataCotiz[0])
            //console.log(dataCotiz[0])
            const linkServiceDetal = baseUrlDB + '/DetallesCotizacionPorId/' + cotizId;
            const responseDetal = await axios.get(linkServiceDetal)
            const dataDetal = responseDetal.data;
            setDetalToEdit(dataDetal)

            const linkService2 = baseUrlDB + '/usuariosInge';
            const response2 = await axios.get(linkService2)
            const engi = response2.data;
            setLastCotiz(dataCotiz[0])
            navigateAssingEngi();
        } catch (error) {
            console.error('Error al encontrar el usuario:', error);
            throw error;
        }
    }

    const clickToGoAddPdf = async (idCot) => {
        try {
            const cotizId = idCot;
            const linkService = baseUrlDB + '/CotizacionPorId/' + cotizId;
            const response = await axios.get(linkService)
            const dataCotiz = response.data;
            setLastCotiz(dataCotiz[0])
            navigateSaveInform();
        } catch (error) {
            console.error('Error al encontrar el usuario:', error);
            throw error;
        }
    }

    const clickToGoViewPdf = async (idInfo) => {

        try {

            const idInform = idInfo;
            const linkService = baseUrlDB + '/informePorId/' + idInform;
            const response = await axios.get(linkService)
            const dataInfo = response.data;
            setLastInfor(dataInfo[0])
            const cotizId = dataInfo[0].idcotizacion;
            const linkServiceCot = baseUrlDB + '/CotizacionPorId/' + cotizId;
            const responseCot = await axios.get(linkServiceCot)
            const dataCotiz = responseCot.data;
            setLastCotiz(dataCotiz[0])

            const linkServiceDetal = baseUrlDB + '/DetallesInformePorId/' + idInform;
            const responseDetal = await axios.get(linkServiceDetal)
            const dataDetal = responseDetal.data;
            setDetalInfor(dataDetal)
            navigateViewInform()
        } catch (error) {
            console.error('Error al encontrar el usuario:', error);
            throw error;
        }
    }

    const clickGoGenerateInform = (equipo, datos, items1, items2, items3, items4) => {
        setPruebasCual(items1)
        setPruebasCuan(items2)
        setMantenPrev(items3)
        setTestAceptacion(items4)
        setDatosInforme(datos)
        setLastEquip(equipo)
        navigateInformGenerator();
    }

    const clickGoSaveInform = async (equipo, datos, items1, items2, items3, items4) => {
        try {
            const respuesta = await axios.post(baseUrlDB + '/agregarInforme', datos, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (Object.keys(items1).length === 0) {
                console.log('El conjunto de objetos "data" está vacío.');
            } else {
                for (const [key, value] of Object.entries(items1)) {
                    const datosDetalle = {
                        TextoItem: key,
                        tipoItem: value.typeItem,
                        estado: value.value,
                    };
                    const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleInforme', datosDetalle, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
            if (Object.keys(items2).length === 0) {
                console.log('El conjunto de objetos "data" está vacío.');
            } else {
                for (const [key, value] of Object.entries(items2)) {
                    const datosDetalle = {
                        TextoItem: key,
                        tipoItem: value.typeItem,
                        estado: value.value,
                    };
                    const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleInforme', datosDetalle, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
            if (Object.keys(items3).length === 0) {
                console.log('El conjunto de objetos "data" está vacío.');
            } else {
                for (const [key, value] of Object.entries(items3)) {
                    const datosDetalle = {
                        TextoItem: key,
                        tipoItem: value.typeItem,
                        estado: value.value,
                    };
                    const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleInforme', datosDetalle, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
            if (Object.keys(items4).length === 0) {
                console.log('El conjunto de objetos "data" está vacío.');
            } else {
                for (const [key, value] of Object.entries(items4)) {
                    const datosDetalle = {
                        TextoItem: key,
                        tipoItem: value.typeItem,
                        estado: value.value,
                    };
                    const respuesta2 = await axios.post(baseUrlDB + '/agregarDetalleInforme', datosDetalle, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                }
            }
            setLastMessage('Informe agregado')
            //navigateToGestionCotiz();
            console.log('Informe registrado:', respuesta.data);
        } catch (error) {
            console.error('Error al registrar informe:', error);
            throw error;
        }
    }

    //Contantes para navegar a...
    const navigateToGestionCotiz = () => {
        // 👇️ navigate to /
        navigate('/GestionCotiz', { replace: true })
    }

    const navigateToAprovedCotiz = () => {
        // 👇️ navigate to /
        navigate('/AprovedCotiz', { replace: true })
    }

    const navigateToGestionUsers = () => {
        // 👇️ navigate to /
        navigate('/GestionUsers', { replace: true })
    }

    const navigateToManagerEquipment = () => {
        // 👇️ navigate to /
        navigate('/ManagementEquipment', { replace: true })
    }

    const navigateManagementEntities = () => {
        navigate('/ManagementEntities', { replace: true })
    }

    const navigateToLogin = () => {
        // 👇️ navigate to /
        navigate('/', { replace: true })
    }

    const navigateToUserRegister = () => {
        // 👇️ navigate to /
        navigate('/UserRegister', { replace: true })
    }

    const navigateToStadisctics = () => {
        navigate('/Stadistics', { replace: true })
    }

    const navigateCreateCotiz = () => {
        navigate('/CreateCotiz', { replace: true })
    }

    const navigatePDFQuote = () => {
        navigate('/PDFQuote', { replace: true })
    }

    const navigateEditUser = () => {
        navigate('/EditUser', { replace: true })
    }

    const navigateEditcotiz = () => {
        navigate('/EditCotiz', { replace: true })
    }

    const navigateAsignedCotiz = () => {
        navigate('/AsignedCotiz', { replace: true })
    }

    const navigateAssingEngi = () => {
        navigate('/AssingEngi', { replace: true })
    }

    const navigateSaveInform = () => {
        navigate('/SaveInform', { replace: true })
    }

    const navigateInformGenerator = () => {
        navigate('/InformGenerator', { replace: true })
    }

    const navigateInformList = () => {
        navigate('/InformList', { replace: true })
    }

    const navigateViewInform = () => {
        navigate('/ViewInform', { replace: true })
    }

    // funciones para navegar a devolver componentes en orden


    function FunMenuSuperUser() {
        var menuRol = null;

        switch (sessionInfo.rol) {
            case 'Director':
                menuRol = <MenuLateralSuper info={sessionInfo.nameuser} gohome={navigateToLogin} goStadistics={navigateToStadisctics} addCotiz={navigateCreateCotiz} addEntitie={openPopupAddEntitie}
                    addEquip={openPopupEqu} goCreateUser={navigateToUserRegister} goTogestionCotiz={navigateToGestionCotiz}
                    goTogestionUsers={navigateToGestionUsers} goToManegementEquipment={navigateToManagerEquipment}
                    goToManegementEntitie={navigateManagementEntities} goAprovedCotiz={navigateToAprovedCotiz} goAssigned={navigateAsignedCotiz}
                    goListInform={navigateInformList} />
                break;
            case 'Auxiliar admin':
                menuRol = <MenuLateralAxuliarAdmin info={sessionInfo.nameuser} goStadistics={navigateToStadisctics} addCotiz={navigateCreateCotiz} addEntitie={openPopupAddEntitie}
                    addEquip={openPopupEqu} goTogestionCotiz={navigateToGestionCotiz} goToManegementEquipment={navigateToManagerEquipment}
                    goToManegementEntitie={navigateManagementEntities} />
                break;
            default:
                menuRol = <MenuLateralInge info={sessionInfo.nameuser} gohome={navigateToLogin} goStadistics={navigateToStadisctics} addCotiz={navigateCreateCotiz}
                    addEquip={openPopupEqu} goTogestionCotiz={navigateToGestionCotiz}
                    goToManegementEquipment={navigateToManagerEquipment}
                    goAprovedCotiz={navigateToAprovedCotiz} goAssigned={navigateAsignedCotiz} goListInform={navigateInformList} />
                break;
        }

        return <Fragment>
            <Popup isOpen={isPopupOpenEnt} onClose={closePopupEnt}>
                {/* Contenido dentro del Popup */}
                {/*<h2>Este es un Popup</h2>*/}
                <EntitieRegistration TodoClick={AddNewEntitie} urldb={baseUrlDB} />
            </Popup>
            <Popup2 isOpen={isPopupOpenEqu} onClose={closePopupEqu}>
                {/* Contenido dentro del Popup */}
                {/*<h2>Este es un Popup</h2>*/}
                <EquipRegistrationPop TodoClick={AddNewEquip} urldb={baseUrlDB} />
            </Popup2>

            {menuRol}
            <MenuSuperUser info={sessionInfo} gohome={navigateToLogin} />

        </Fragment>
    }

    function FunGestionCotiz() {
        const menuSuper = FunMenuSuperUser();
        //console.log(sessionInfo.nameuser)
        return <div className='div-super-container'>
            {menuSuper}
            {/*<button onClick={openPopup}>Abrir Popup</button>*/}
            <div className='content'>
                <GestionCotiz goToCreateCotiz={navigateCreateCotiz} message={lastMessage} goToEditCotiz={clickToGoEditCotiz} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunAprovedCotiz() {
        const menuSuper = FunMenuSuperUser();
        //console.log(sessionInfo.nameuser)

        return <div className='div-super-container'>
            {menuSuper}
            {/*<button onClick={openPopup}>Abrir Popup</button>*/}
            <div className='content'>
                <AprovedCotiz goAssignEngi={clickToGoAssignEngi} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunGestionUsers() {
        const menuSuper = FunMenuSuperUser();
        const [message, setmessage] = useState(lastMessage)


        return <div className='div-super-container'>
            {menuSuper}
            {/*<button onClick={openPopup}>Abrir Popup</button>*/}
            <div className='content'>
                <GestionUsers goToCreateUsers={navigateToUserRegister} goEditUser={clickToGoEditUser} message={message} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunGestionEquipmet() {
        const menuSuper = FunMenuSuperUser();
        //console.log(sessionInfo.nameuser)
        return <div className='div-super-container'>
            {menuSuper}
            {/*<button onClick={openPopup}>Abrir Popup</button>*/}
            <div className='content'>
                <ManagementEquipment urldb={baseUrlDB} goToCreatEquip={openPopupEqu} />
            </div>
        </div>
    }

    function FunGestionEntitie() {
        const menuSuper = FunMenuSuperUser();
        //console.log(sessionInfo.nameuser)
        return <div className='div-super-container'>
            {menuSuper}
            {/*<button onClick={openPopup}>Abrir Popup</button>*/}
            <div className='content'>
                <ManagementEntitites goToCreatEntitie={openPopupAddEntitie} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunLogin() {
        //aqui puede ir un menu distinto cada vez
        return <div className='div-super-container'>
            <Login TodoClick={clickToLogIn} />
        </div>
    }

    function FunUserRegister() {
        //aqui puede ir un menu distinto cada vez
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <UserRegister TodoClick={ClickToAddUser} sendEditedUser={clickToGoEditUser} showEdit={false} />
            </div>
        </div>
    }

    function FunStadistics() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <Stadistics rol={sessionInfo.rol} goCotiz={navigateToGestionCotiz}
                    Goaproved={navigateToAprovedCotiz} goEqui={navigateToManagerEquipment} goAsig={navigateAsignedCotiz} goent={navigateManagementEntities}
                    gousers={navigateToGestionUsers} goinform={navigateInformList}/>
            </div>
        </div>
    }

    function FunCreateCotiz() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <CreateCotiz TodoClick={ClickToCreateCotiz} openAddEnt={openPopupAddEntitie}
                    exitCotiz={navigateToStadisctics} goCreatePDF={ClickToCreateQuotePDF}
                    showEdit={false} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunCreateQuote() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <PdfQuote equipos={cotizInfo.equipos} entidadSeleccinada={cotizInfo.entidadSeleccinada}
                    IVA={cotizInfo.IVA} Consumibles={cotizInfo.consum} TOTAL={cotizInfo.total}
                    currentDate={cotizInfo.fecha} endDate={cotizInfo.fechaFin} UserGenerator={sessionInfo.nameuser} />
            </div>
        </div>
    }

    function FunEditUser() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <UserRegister TodoClick={ClickToAddUser} sendEditedUser={ClickToEditUserNow} showEdit={true} user={userToEdit} />
            </div>
        </div>
    }

    function FunEditCotiz() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <CreateCotiz TodoClick={ClickToCreateCotiz} openAddEnt={openPopupAddEntitie}
                    exitCotiz={navigateToStadisctics} goCreatePDF={ClickToCreateQuotePDF}
                    showEdit={true} dataCotiz={cotizToEdit} dataEquip={detalToEdit} SendEditedCotiz={ClickToUpdateCotiz} urldb={baseUrlDB} />
            </div>
        </div>
    }

    function FunAsignedCotiz() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <AssignedCotiz goAddPDF={clickToGoAddPdf} urldb={baseUrlDB} iduser={sessionInfo.keyuser} />
            </div>
        </div>
    }

    function FunSaveInform() {
        const menuSuper = FunMenuSuperUser();
        //console.log(lastCotiz)
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <SaveInform goSaveInform={clickGoSaveInform} goInformeGenerator={clickGoGenerateInform} urldb={baseUrlDB} cotiz={lastCotiz} goAddEqui={openPopupEqu} idInge={sessionInfo.keyuser} />
            </div>
        </div>
    }

    function FunAssingengi() {
        const menuSuper = FunMenuSuperUser();
        
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <AssingEngi cothospital={lastCotiz} urldb={baseUrlDB} detal={detalToEdit}/>
            </div>
        </div>
    }

    function FunInformeGenerator() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <InformGenerator inge={sessionInfo} entitie={lastCotiz} equip={lastEquip} PruebasCual={PruebasCual} PruebasCuan={PruebasCuan}
                    MantenPrev={MantenPrev} testAceptacion={testAceptacion} datos={datosInforme} />
            </div>
        </div>
    }

    function FunInformList() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <InformList goAddPDF={clickToGoViewPdf} urldb={baseUrlDB} iduser={sessionInfo.keyuser} />
            </div>
        </div>
    }

    function FunViewInform() {
        const menuSuper = FunMenuSuperUser();
        return <div className='div-super-container'>
            {menuSuper}
            <div className='content'>
                <SaveInform goSaveInform={clickGoSaveInform} goInformeGenerator={clickGoGenerateInform} 
                urldb={baseUrlDB} cotiz={lastCotiz} goAddEqui={openPopupEqu} idInge={sessionInfo.keyuser} 
                dataInform={lastInfor} details={detalnfor} editing={true}/>
            </div>
        </div>
    }

    //return principal de App para cambio de paginas(Tener funcion con compoenete primero)
    return (
        <Fragment>
            <Routes>
                <Route path='/GestionCotiz' element={<FunGestionCotiz />} />
                <Route path='/AprovedCotiz' element={<FunAprovedCotiz />} />
                <Route path='/GestionUsers' element={<FunGestionUsers />} />
                <Route path='/ManagementEquipment' element={<FunGestionEquipmet />} />
                <Route path='/ManagementEntities' element={<FunGestionEntitie />} />
                <Route path='/UserRegister' element={<FunUserRegister />} />
                <Route path='/Stadistics' element={<FunStadistics />} />
                <Route path='/CreateCotiz' element={<FunCreateCotiz />} />
                <Route path='/PDFQuote' element={<FunCreateQuote />} />
                <Route path='/' element={<FunLogin />} />
                <Route path='/EditUser' element={<FunEditUser />} />
                <Route path='/EditCotiz' element={<FunEditCotiz />} />
                <Route path='/AsignedCotiz' element={<FunAsignedCotiz />} />
                <Route path='/SaveInform' element={<FunSaveInform />} />
                <Route path='/AssingEngi' element={<FunAssingengi />} />
                <Route path='/InformList' element={<FunInformList />} />
                <Route path='/ViewInform' element={<FunViewInform />} />
                <Route path='/InformGenerator' element={<FunInformeGenerator />} />
            </Routes>
        </Fragment>
    );
}