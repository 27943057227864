import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
//import { format } from 'date-fns';

export function AprovedCotiz({ goAssignEngi, urldb }) {

    const [cotizaciones, setCotizaciones] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    //constante de busqueda
    const [searchValue, setSearchValue] = useState('');
    const [selectedOption, setSelectedOption] = useState('idcotizacion');

    const clickSearch = async () => {
        //console.log('Valor de búsqueda:', searchValue);
        //console.log('Valor seleccionado:', selectedOption);
        const columna = selectedOption;
        var valor = searchValue;
        /*eliminar este if si de formatea en api
        if(selectedOption === "fecha"){
            valor = valor + 'T05:00:00.000Z'
            console.log('convert')
        }*/
        try {
            const response = await axios.get(urldb + `/cotizacionesAprobadas?columna=${columna}&valor=${valor}`);
            setCotizaciones(response.data);
        } catch (error) {
            console.error('Hubo un error al obtener los resultados:', error);
        }
    };

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(urldb + '/cotizacionesAprobadas')
            .then((response) => {
                setCotizaciones(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener cotizaciones:', error);
            });
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...

        if (orderBy) {
            cotizaciones.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);

    const clickAssignEngi = (event) => {
        var id = event.target.value
        goAssignEngi(id)
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };


    return (
        <Fragment>
            <h1>Cotizaciones aprovadas</h1>
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                        <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)} />
                        <button style={{ marginRight: "10px" }} id="search-button"
                            className='btn-icono' onClick={clickSearch}>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}>
                            <option value="idcotizacion">Código</option>
                            <option value="nombreComercial">Entidad Cliente</option>
                            <option value="valorttotal">Precio Total</option>
                            <option value="contactoentidad">Correo de Contacto</option>
                            <option value="fecha">Fecha de Generación</option>
                            <option value="nombre_usuario">Ingeniero Asignado</option>
                        </select>
                    </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('idcotizacion')}
                                className={orderBy === 'idcotizacion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Código
                            </th>
                            <th onClick={() => handleHeaderClick('nombre_entidad_medica')}
                                className={orderBy === 'nombre_entidad_medica' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Entidad Cliente
                            </th>
                            <th onClick={() => handleHeaderClick('valorttotal')}
                                className={orderBy === 'valorttotal' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Precio Total
                            </th>
                            <th onClick={() => handleHeaderClick('correoContacto')}
                                className={orderBy === 'correoContacto' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Correo de contacto
                            </th>
                            <th onClick={() => handleHeaderClick('estado')}
                                className={orderBy === 'estado' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Estado
                            </th>
                            <th onClick={() => handleHeaderClick('fechaGeneracion')}
                                className={orderBy === 'fechaGeneracion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Fecha de Generación
                            </th>
                            <th onClick={() => handleHeaderClick('nombre_usuario')}
                                className={orderBy === 'nombre_usuario' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Ingeniero Asignado
                            </th>
                            <th>Asignar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cotizaciones.map((cotizacion, index) => {
                            // Parsear y formatear la fecha
                            let fechaOriginal = cotizacion.fecha;
                            let partesFecha = fechaOriginal.split('/');
                            let fechaParseada = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]);
                            let dia = fechaParseada.getDate().toString().padStart(2, '0');
                            let mes = (fechaParseada.getMonth() + 1).toString().padStart(2, '0');
                            let anio = fechaParseada.getFullYear();
                            let fechaFormateada = `${dia}-${mes}-${anio}`;

                            return (
                                <tr key={index}>
                                    <td>{cotizacion.idcotizacion}</td>
                                    <td>{cotizacion.nombre_entidad_medica}</td>
                                    <td>{cotizacion.valorttotal}</td>
                                    <td>{cotizacion.contactoentidad}</td>
                                    <td>{cotizacion.estado}</td>
                                    <td>{fechaFormateada}</td> {/* Aquí se muestra la fecha formateada */}
                                    <td>{cotizacion.nombre_usuario}</td>
                                    <td>
                                        <button className='btn' onClick={clickAssignEngi} value={cotizacion.idcotizacion}>
                                            Asignar
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </section>

        </Fragment>
    )
}
export default AprovedCotiz