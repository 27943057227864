import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

export function InformList({ goAddPDF, urldb, iduser }) {

    const [cotizaciones, setCotizaciones] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
        axios.get(urldb + '/busquedaInformes')
            .then((response) => {
                setCotizaciones(response.data); // Almacena los resultados en el estado "usuarios"
            })
            .catch((error) => {
                console.error('Error al obtener informes:', error);
            });
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...

        if (orderBy) {
            cotizaciones.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);



    const clickNavAddpdf = (event) => {
        event.preventDefault()
        goAddPDF(event.target.value);
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };


    return (
        <Fragment>
            <h1>Listado de informes</h1>
            <section>
                <div className='shadow-div'>
                    <div className="search-bar">
                        <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." />
                        <button style={{ marginRight: "10px" }} id="search-button" className='btn-icono'>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                        <select>
                            <option value="codigo">Código</option>
                            <option value="entidad">Entidad Cliente</option>
                            <option value="precio">Precio Total</option>
                            <option value="correo">Correo de Contacto</option>
                            <option value="fecha">Fecha de Generación</option>
                            <option value="ingeniero">Ingeniero Asignado</option>
                        </select>
                    </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('idinforme')}
                                className={orderBy === 'idinforme' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                ID informe
                            </th>
                            <th onClick={() => handleHeaderClick('codigoinforme')}
                                className={orderBy === 'codigoinforme' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Codigo informe
                            </th>
                            <th style={{ whiteSpace: "nowrap" }} onClick={() => handleHeaderClick('nombreentidad"')}
                                className={orderBy === 'nombreentidad"' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Entidad Cliente
                            </th>
                            <th onClick={() => handleHeaderClick('tipoequipo')}
                                className={orderBy === 'tipoequipo' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Tipo equipo
                            </th>
                            <th onClick={() => handleHeaderClick('estado')}
                                className={orderBy === 'estado' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Estado
                            </th>
                            <th onClick={() => handleHeaderClick('fechaGeneracion')}
                                className={orderBy === 'fechaGeneracion' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Fecha de Generación
                            </th>
                            <th onClick={() => handleHeaderClick('ingenieroAsignado')}
                                className={orderBy === 'ingenieroAsignado' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Ingeniero Asignado
                            </th>
                            <th onClick={() => handleHeaderClick('frecmantenimiento')}
                                className={orderBy === 'frecmantenimiento' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Frec. mantenimiento
                            </th>
                            <th>Revisar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cotizaciones.map((informe, index) => (
                            <tr key={index}>
                                <td>{informe.idinforme}</td>
                                <td>{informe.codigoinforme}</td>
                                <td>{informe.nombreentidad}</td>
                                <td>{informe.tipoequipo}</td>
                                <td>{informe.estado}</td>
                                <td>{format(new Date(informe.fecha_inicio), 'dd/MM/yyyy')}</td>
                                <td>{informe.nombreingeniero}</td>
                                <td>{informe.frecmantenimiento}</td>
                                <td><button onClick={clickNavAddpdf} className='btn' value={informe.idinforme}>Revisar</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>

        </Fragment>
    )
}
export default InformList