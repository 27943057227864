import React from 'react';

export function Popup2({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <div className="popup-overlay" style={{  maxHeight: "100vh", overflowY: "auto"}}>
            <div className="popup" onClick={(e) => e.stopPropagation()} >
                <button style={{
                    backgroundColor: "#B13E22",
                    color: "#FFF",
                }} className="btn-close" onClick={onClose}>
                    X
                </button>
                <div >
                    {children}
                </div>

            </div>
        </div>
    );
}
