import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, PDFViewer, Image, Table } from '@react-pdf/renderer';

const logoImg = process.env.PUBLIC_URL + '/bases images/LN1.png';
const checkImg = process.env.PUBLIC_URL + '/bases images/check.png';
const QRImg = process.env.PUBLIC_URL + '/bases images/QRBioAsesores.PNG';
const failImg = process.env.PUBLIC_URL + '/bases images/fail.png';

/*Font.register({
  family: 'Arial',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/arial.ttf',
},{
  family: 'Roboto-Bold',
  src: './fonts/Roboto-Bold.ttf',
});*/
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
}, {
    family: 'Arial',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/Arial.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: '#FFFFFF',
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    logoContain: {
        flexDirection: 'row',
        justifyContent: 'left',
        backgroundColor: '#ffffff',
        padding: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#000000',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    DataHeaderContain: {
        flexDirection: 'column',
        justifyContent: 'left',
        backgroundColor: '#ffffff',
        padding: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#000000',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    backgroundImageLogo: {
        left: 0,
        top: 0,
        width: '30%', // Ocupa todo el ancho del encabezado
        height: '80%', // Ocupa todo el alto del encabezado
    },
    qrImage: {
        position: 'absolute',
        left: 484,
        top: 0,
        width: '12%', // Ocupa todo el ancho del encabezado
        height: '100%', // Ocupa todo el alto del encabezado
        opacity: 0.9, // Ajusta la opacidad de la imagen según desees
    },
    logoText: {
        position: 'absolute',
        left: 250,
        top: 0,
        width: '40%',
        fontSize: 8,
        textAlign: 'left',
        lineHeight: 1.4,
        padding: 3,
    },
    titleRowHiden: {
        display: 'flex',
        flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        justifyContent: 'space-between',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'right',
    },

    rowBottom: {
        display: 'flex',
        flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        justifyContent: 'space-between',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'center',
        borderBottom: '1px',
        borderBottomWidth: 1,
        borderStyle: 'solid',
    },
    //tabla de datos
    genericCell: {
        fontSize: 8,
        color: '#000',
        padding: '1px', // Añade espacio interno
        textAlign: 'left',
        //borderRightWidth: 1,
        borderLeftWidth: 1,
        borderStyle: 'solid',
    },

    emptyBox: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    titleRow: {
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: '#dbedf4',
        padding: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderColor: '#000000',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    titleText: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    rowTitleBottom: {
        display: 'flex',
        flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'center',
        borderBottom: '1px',
        borderBottomWidth: 1,
        borderStyle: 'solid',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'center',
    },
    col1WhiteTitle: {
        width: '80%',
        //border: '1px solid #daedf7', // Define el borde
        backgroundColor: '#FFF',
        fontSize: 8,
        color: '#000',
        padding: '1px', // Añade espacio interno
        textAlign: 'left',

    },
    col2WhiteTitle: {
        width: '7%',
        //border: '1px solid #daedf7', // Define el borde
        backgroundColor: '#FFF',
        fontSize: 8,
        color: '#000',
        padding: '1px', // Añade espacio interno
        textAlign: 'center'
    },
    col1White: {
        width: '80%',
        //border: '1px solid #daedf7', // Define el borde
        backgroundColor: '#FFF',
        fontSize: 8,
        color: '#000',
        padding: '1px', // Añade espacio interno
        textAlign: 'left',
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderStyle: 'solid',
    },
    col2White: {
        width: '7%',
        //border: '1px solid', // Define el borde
        backgroundColor: '#FFF',
        fontSize: 8,
        color: '#000',
        //padding: '1px', // Añade espacio interno
        borderRightWidth: 1,
        //borderLeftWidth: 1,
        textAlign: 'center',
        borderStyle: 'solid',
    },
    colFullWhite: {
        width: '100%',
        //border: '1px solid', // Define el borde
        backgroundColor: '#FFF',
        fontSize: 8,
        color: '#000',
        //padding: '1px', // Añade espacio interno
        borderRightWidth: 1,
        //borderLeftWidth: 1,
        textAlign: 'left',
        borderStyle: 'solid',
    },
    rowHalf: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        //borderTop: '1px solid #EEE',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'left',
        borderBottom: '1px',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',

    },
    textFirm: {
        fontSize: 8,
        textAlign: 'left',
    },
    textFirmBold: {
        fontSize: 8,
        fontWeight: 2500,
        textAlign: 'left',
    },
    rowUnderLine: {
        //display: 'flex',
        //flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',
        alignItems: 'center',
        borderTop: '3px',
        borderTopWidth: 3,
        borderStyle: 'solid',
    },
    rowVoidTop: {
        //display: 'flex',
        //flexDirection: 'row',
        //borderTop: '1px solid #EEE',
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '#FFF',
        color: '#000',

    },
});



const InformGenerator = ({inge, entitie, equip ,PruebasCual, PruebasCuan, MantenPrev, testAceptacion, datos }) => {
    const MAX_TABLE_HEIGHT = 450; // Altura máxima permitida para la tabla en una página

    if (inge){
        console.log('hay inge')
        console.log(inge)
    }

    if (entitie){
        console.log('hay entidad')
        console.log(entitie)
    }

    if (equip){
        console.log('hay equipo')
        console.log(equip)
    }

    if (datos){
        console.log('hay datos')
        console.log(datos)
    }

    return (
        <PDFViewer width="100%" height="500px">
            <Document>
                <Page size="A4" style={styles.page} >
                    <View style={styles.section}>
                        {/* Encabezado de la tabla */}
                        <View style={styles.logoContain}>
                            <Image src={logoImg} style={styles.backgroundImageLogo} />
                            <Text style={styles.logoText}>
                                Calle 110 No. 22A - 104 Provenza, Bucaramanga{"\n"}
                                6 851424       300 4941066{"\n"}
                                www.bioasesores.com.co{"\n"}
                                soporteingenieris@bioasesores.com.co{"\n"}
                                info@bioasesores.com.co{"\n"}
                            </Text>
                            <Image src={QRImg} style={styles.qrImage} />
                        </View>
                        <View style={styles.titleRowHiden}>
                            <Text style={styles.textFirmBold}>  REPORTE DE INGENIERÍA</Text>
                            <Text style={styles.textFirmBold}>  Reporte Preventivo No.</Text>
                            <Text style={[styles.textFirmBold, { color: 'blue' }]}> {datos.codigoInforme}</Text>
                        </View>
                        <View style={styles.DataHeaderContain}>
                            <View style={styles.rowBottom}>
                                <Text style={[styles.genericCell, { width: `18%` }]}>INSTITUCION O CLIENTE</Text>
                                <Text style={[styles.genericCell, { width: `50%` }]}>{entitie.razonsocial}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>Fecha</Text>
                                <Text style={[styles.genericCell, { width: `20%`, textAlign: 'center' }]}>{datos.fecha_inicio}</Text>
                            </View>
                            <View style={styles.rowBottom}>
                                <Text style={[styles.genericCell, { width: `10%` }]}>EQUIPO</Text>
                                <Text style={[styles.genericCell, { width: `30%`, textAlign: 'center' }]}>{equip.tipoequipo}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>MARCA</Text>
                                <Text style={[styles.genericCell, { width: `20%`, textAlign: 'center' }]}>{equip.marca}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>MODELO</Text>
                                <Text style={[styles.genericCell, { width: `10%`, textAlign: 'center' }]}>{equip.modelo}</Text>
                            </View>
                            <View style={styles.rowBottom}>
                                <Text style={[styles.genericCell, { width: `10%` }]}>N. SERIE</Text>
                                <Text style={[styles.genericCell, { width: `10%`, textAlign: 'center' }]}>{equip.serialequipo}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>UBICACIÓN</Text>
                                <Text style={[styles.genericCell, { width: `20%`, textAlign: 'center' }]}> {datos.ubicacion}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>SEDE</Text>
                                <Text style={[styles.genericCell, { width: `20%`, textAlign: 'center' }]}> {datos.sede}</Text>
                            </View>
                            <View style={styles.rowBottom}>
                                <Text style={[styles.genericCell, { width: `10%` }]}>N. ACTIVO</Text>
                                <Text style={[styles.genericCell, { width: `10%`, textAlign: 'center' }]}>{datos.numeroActivo}</Text>
                                <Text style={[styles.genericCell, { width: `10%` }]}>C. ECRI</Text>
                                <Text style={[styles.genericCell, { width: `10%`, textAlign: 'center' }]}>{datos.CodEri}</Text>
                                <Text style={[styles.genericCell, { width: `15%` }]}>C. BIOMÉDICA</Text>
                                <Text style={[styles.genericCell, { width: `10%`, textAlign: 'center'}]}>{datos.CBiomedica === '0' || datos.CBiomedica === 0 ? 'n/p' : `nivel ${datos.CBiomedica}`}</Text>
                                <Text style={[styles.genericCell, { width: `15%` }]}>F.MANTE</Text>
                                <Text style={[styles.genericCell, { width: `20%`, textAlign: 'center' }]}> {datos.FrecMantenimiento}</Text>
                            </View>
                        </View>
                        <View style={styles.titleRow}>
                            <Text style={styles.titleText}>PRUEBAS CUALITATIVAS</Text>
                        </View>
                        <View style={styles.rowTitleBottom}>
                            <Text style={styles.col1WhiteTitle}>TEST DE INSPECCIÓN Y FUNCIONALIDAD</Text>
                            <Text style={styles.col2WhiteTitle}>APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APLI</Text>
                        </View>
                        {Object.keys(PruebasCual).map((key, index) => (
                            <View style={styles.row} key={index}>
                                <Text style={styles.col1White}>{key}</Text>
                                <Text style={styles.col2White}>{PruebasCual[key].value === 'apto' ? <Image src={checkImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{PruebasCual[key].value === 'no apto' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{PruebasCual[key].value === 'no apli' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                            </View>
                        ))}
                        <View style={styles.titleRow}>
                            <Text style={styles.titleText}>PRUEBAS CUANTITATIVAS</Text>
                        </View>
                        <View style={styles.rowTitleBottom}>
                            <Text style={styles.col1WhiteTitle}>MEDICIONES REALIZADAS</Text>
                            <Text style={styles.col2WhiteTitle}>APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APLI</Text>
                        </View>
                        {Object.keys(PruebasCuan).map((key, index) => (
                            <View style={styles.row} key={index}>
                                <Text style={styles.col1White}>{key}</Text>
                                <Text style={styles.col2White}>{PruebasCuan[key].value === 'apto' ? <Image src={checkImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{PruebasCuan[key].value === 'no apto' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{PruebasCuan[key].value === 'no apli' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                            </View>
                        ))}
                        <View style={styles.titleRow}>
                            <Text style={styles.titleText}>MANTENIMIENTO PREVENTIVO</Text>
                        </View>
                        <View style={styles.rowTitleBottom}>
                            <Text style={styles.col1WhiteTitle}>ACTIVIDADES REALIZADAS</Text>
                            <Text style={styles.col2WhiteTitle}>Realiza</Text>
                            <Text style={styles.col2WhiteTitle}>No APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APLI</Text>
                        </View>
                        {Object.keys(MantenPrev).map((key, index) => (
                            <View style={styles.row} key={index}>
                                <Text style={styles.col1White}>{key}</Text>
                                <Text style={styles.col2White}>{MantenPrev[key].value === 'apto' ? <Image src={checkImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{MantenPrev[key].value === 'no apto' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{MantenPrev[key].value === 'no apli' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                            </View>
                        ))}
                        <View style={styles.titleRow}>
                            <Text style={styles.titleText}>TEST DE ACEPTACIÓN</Text>
                        </View>
                        <View style={styles.rowTitleBottom}>
                            <Text style={styles.col1WhiteTitle}>PRUEBAS FINALES</Text>
                            <Text style={styles.col2WhiteTitle}>APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APTO</Text>
                            <Text style={styles.col2WhiteTitle}>No APLI</Text>
                        </View>
                        {Object.keys(testAceptacion).map((key, index) => (
                            <View style={styles.row} key={index}>
                                <Text style={styles.col1White}>{key}</Text>
                                <Text style={styles.col2White}>{testAceptacion[key].value === 'apto' ? <Image src={checkImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{testAceptacion[key].value === 'no apto' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                                <Text style={styles.col2White}>{testAceptacion[key].value === 'no apli' ? <Image src={failImg} style={{ width: 8, height: 10 }} /> : ' '}</Text>
                            </View>
                        ))}
                        <View style={styles.titleRow}>
                            <Text style={styles.titleText}> OBSERVACIONES</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.colFullWhite}> El equipo funciona correctamente y se encuentra apto para uso.</Text>
                        </View>
                        <View style={styles.row}>
                            <View style={styles.rowHalf}>
                                <View style={styles.rowVoidTop}>
                                    <Text style={styles.textFirm}>NOMBRE:{"\n"}
                                        <Text style={styles.textFirmBold}> {inge.nameuser}</Text>{"\n"}
                                        Responsable del Matto.
                                    </Text>
                                </View>
                                <View style={styles.rowVoidTop}>
                                    <Text> </Text>
                                    <View style={styles.rowUnderLine}><Text style={styles.textFirm}> FIRMA</Text> </View>
                                </View>
                            </View>
                            <View style={styles.rowHalf}>
                                <View style={styles.rowVoidTop}>
                                    <Text style={styles.textFirm}>NOMBRE:{"\n"}
                                        <Text style={styles.textFirmBold}>{"\n"} </Text>
                                        Responsable Institucion.
                                    </Text>
                                </View>
                                <View style={styles.rowVoidTop}>
                                    <Text> </Text>
                                    <View style={styles.rowUnderLine}><Text style={styles.textFirm}> FIRMA</Text> </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer >
    );
};

export default InformGenerator;
