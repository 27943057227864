import React, { useState, useEffect, Fragment } from 'react';
/*
import { saveAs } from 'file-saver';
import { PDFDocument } from 'pdf-lib';
import { useDropzone } from 'react-dropzone';
*/
import axios from 'axios';
import Autosuggest from 'react-autosuggest';

export function SaveInform({ goInformeGenerator, goSaveInform, editing, urldb, cotiz, goAddEqui, idInge, dataInform, details }) {

    //si van a agregar mas secciones que no pase de 8 letras
    const TipoPruebasCualitativas = 'PrueCual';
    const TipoPruebasCuantitativas = 'PrueCuan';
    const TipoMantenimientoPreventivo = 'ManPrev';
    const TipoTestAceptacion = 'TestAcep';

    const getCurrentDate = () => {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const year = today.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [datos, setDatos] = useState({
        idEquip: '',
        serialEquip: '',
        ubicacion: '',
        sede: '',
        numActivo: '',
        CodEcri: 'n/p',
        biomedica: '0',
        Fmante: 'Anual',
    })

    const [PRUEBASCUALITATIVAS, setCuali] = useState([
        'Estado general del equipo, carcazas y laminas',
        'Estado del cable de poder y clavija',
        'Estado del swiche de encendido',
        'Integridad del motor',
    ])

    const [PRUEBASCUANTITATIVAS, setCuanti] = useState([
        'Verificación del consumo de corriente según especificaciones técnicas',
        'Verificación del voltaje de alimentación',
    ])

    const [MANTENIMIENTOPREVENTIVO, setMante] = useState([
        'Limpieza general del equipo',
        'Limpieza y ajuste de conectores y cableado interno del equipo',
        'Limpieza y ajuste del swiche de encendido',
    ])

    const [TESTDEACEPTACION, setTest] = useState([
        'Pruebas finales de funcionamiento con carga',
        'Pruebas de seguridad eléctrica',
    ])

    const handleInputChange = (event) => {
        //console.log(event.target.name)
        //console.log(event.target.value)
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })
    }

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setDatos((prevDatos) => ({
            ...prevDatos,
            [name]: value,
        }));
    };
    //valor inicail
    useEffect(() => {
        const initialCheckboxes = {};
        if (dataInform && editing) {
            console.log('datos a cargar');
            findEquip(dataInform.serialequipo)
            setDatos({
                ...datos,
                ubicacion: dataInform.ubicacion,
                sede: dataInform.sede,
                numActivo: dataInform.numeroactivo,
                CodEcri: dataInform.coderi,
                biomedica: dataInform.cbiomedica,
                Fmante: dataInform.frecmantenimiento,
            })
            const hayPruebasCual = details.some(item => item.tipoitem === TipoPruebasCualitativas);
            if (!hayPruebasCual) {
                PRUEBASCUALITATIVAS.forEach((pregunta) => {
                    initialCheckboxes[pregunta] = {
                        value: 'no apli',
                        typeItem: TipoPruebasCualitativas,
                    };
                });
            }

            const hayPruebasCuan = details.some(item => item.tipoitem === TipoPruebasCuantitativas);
            if (!hayPruebasCuan) {
                PRUEBASCUANTITATIVAS.forEach((pregunta) => {
                    initialCheckboxes[pregunta] = {
                        value: 'no apli',
                        typeItem: TipoPruebasCuantitativas,
                    };
                });
            }
            const hayMantePrev = details.some(item => item.tipoitem === TipoMantenimientoPreventivo);
            if (!hayMantePrev) {
                MANTENIMIENTOPREVENTIVO.forEach((pregunta) => {
                    initialCheckboxes[pregunta] = {
                        value: 'no apli',
                        typeItem: TipoMantenimientoPreventivo,
                    };
                });
            }

            const haytestAceptacion = details.some(item => item.tipoitem === TipoTestAceptacion);
            if (!haytestAceptacion) {
                TESTDEACEPTACION.forEach((pregunta) => {
                    initialCheckboxes[pregunta] = {
                        value: 'no apli',
                        typeItem: TipoTestAceptacion,
                    };
                });
            }

            details.forEach((item, index) => {
                /*console.log(`Elemento ${index + 1}:`);
                console.log("ID:", item.id);
                
                console.log("Tipo de Item:", item.tipoitem);
                console.log("Estado:", item.estado);
                */
                initialCheckboxes[item.textoitem] = {
                    value: item.estado,
                    typeItem: item.tipoitem,
                    itemId: item.id,
                };
                console.log("Texto del Item:", item.textoitem);
            });

        } else {
            PRUEBASCUALITATIVAS.forEach((pregunta) => {
                initialCheckboxes[pregunta] = {
                    value: 'no apli',
                    typeItem: TipoPruebasCualitativas,
                };
            });
            PRUEBASCUANTITATIVAS.forEach((pregunta) => {
                initialCheckboxes[pregunta] = {
                    value: 'no apli',
                    typeItem: TipoPruebasCuantitativas,
                };
            });
            MANTENIMIENTOPREVENTIVO.forEach((pregunta) => {
                initialCheckboxes[pregunta] = {
                    value: 'no apli',
                    typeItem: TipoMantenimientoPreventivo,
                };
            });
            TESTDEACEPTACION.forEach((pregunta) => {
                initialCheckboxes[pregunta] = {
                    value: 'no apli',
                    typeItem: TipoTestAceptacion,
                };
            });
        }
        setCheckboxes(initialCheckboxes);
    }, []);
//PRUEBASCUALITATIVAS, PRUEBASCUANTITATIVAS, MANTENIMIENTOPREVENTIVO, TESTDEACEPTACION
    const fetchSuggestions = async (serialin) => {
        try {
            const response = await axios.get(`${urldb}/equiposFilter/${serialin}/${cotiz.identidadmedica}`);
            // Maneja la respuesta de tu servicio aquí
            // console.log('Datos de entidades:', response.data);
            // Actualiza las sugerencias con los datos obtenidos
            setSuggestionsList(response.data);
        } catch (error) {
            // Maneja los errores aquí
            console.error('Error al obtener datos de equipos:', error);
        }
    };

    const findEquip = async (serialin) => {
        try {
            const response = await axios.get(`${urldb}/equiposFilter/${serialin}/${cotiz.identidadmedica}`);
            // Maneja la respuesta de tu servicio aquí
            // console.log('Datos de entidades:', response.data);
            // Actualiza las sugerencias con los datos obtenidos
            setSelectedSuggestion(response.data[0])
        } catch (error) {
            // Maneja los errores aquí
            console.error('Error al obtener datos de equipos', error);
        }
    };


    //const de sugerencias
    //texto de selecion
    const [suggestionValue, setSuggestionValue] = useState('');
    //objeto seleccionado
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    //lista de sujerencias
    const [suggestionsList, setSuggestionsList] = useState([]);

    //fijar nueva lista
    const onSuggestionsFetchRequested = ({ value }) => {
        const inputValue = value.trim().toLowerCase();
        /*
        const filteredSuggestions = suggestionsEquip.filter(suggestion =>
            suggestion.nombre_lugar.toLowerCase().includes(inputValue)
        );

        setSuggestionsList(filteredSuggestions);
        */
        fetchSuggestions(inputValue)
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionsList([]);
    };

    //para obtener ip seleccionada
    const onSuggestionSelected = (event, { suggestion }) => {
        setSelectedSuggestion(suggestion);
    };

    //se cambia en valor del input de recomendaciones
    //como hospitales y clinicas
    const handleInputChangeSug = (event, { newValue }) => {
        setSuggestionValue(newValue);
        setSelectedSuggestion(null)
        //setDatos({ ...datos, nameEnt: suggestionValue })

        //aqui se puede llamar la base de datos para actualziar la lista de sugerencias
    };


    ///item de informe

    const [checkboxes, setCheckboxes] = useState({});
    const [newPregunta, setNewPregunta] = useState('');

    const handleCheckboxChange = (item, value) => {
        setCheckboxes({
            ...checkboxes,
            [item]: {
                ...checkboxes[item],
                value: value,
            },
        });
    };

    //Eventos pruebas cuantitativas
    const handleAddCuan = () => {
        if (newPregunta) {
            setCuanti([...PRUEBASCUANTITATIVAS, newPregunta]);
            setCheckboxes({
                ...checkboxes,
                [newPregunta]: {
                    value: 'no apli',
                    atributo: TipoPruebasCuantitativas,
                },
            });
            setNewPregunta('');
        }
    };

    const handleDeleteCuan = (pregunta) => {
        const updatedPreguntas = PRUEBASCUANTITATIVAS.filter((item) => item !== pregunta);
        setCuanti(updatedPreguntas);
    };

    //Eventos pruebas cualitativas
    const handleAddCual = () => {
        if (newPregunta) {
            setCuali([...PRUEBASCUALITATIVAS, newPregunta]);
            setCheckboxes({
                ...checkboxes,
                [newPregunta]: {
                    value: 'no apli',
                    atributo: TipoPruebasCualitativas,
                },
            });
            setNewPregunta('');
        }
    };

    const handleDeleteCual = (pregunta) => {
        const updatedPreguntas = PRUEBASCUALITATIVAS.filter((item) => item !== pregunta);
        setCuali(updatedPreguntas);
    };

    //Eventos pruebas cualitativas
    const handleAddMante = () => {
        if (newPregunta) {
            setMante([...MANTENIMIENTOPREVENTIVO, newPregunta]);
            setCheckboxes({
                ...checkboxes,
                [newPregunta]: {
                    value: 'no apli',
                    atributo: TipoMantenimientoPreventivo,
                },
            });
            setNewPregunta('');
        }
    };

    const handleDeleteMante = (pregunta) => {
        const updatedPreguntas = MANTENIMIENTOPREVENTIVO.filter((item) => item !== pregunta);
        setMante(updatedPreguntas);
    };

    //Eventos pruebas cualitativas
    const handleAddTest = () => {
        if (newPregunta) {
            setTest([...TESTDEACEPTACION, newPregunta]);
            setCheckboxes({
                ...checkboxes,
                [newPregunta]: {
                    value: 'no apli',
                    atributo: TipoTestAceptacion,
                },
            });
            setNewPregunta('');
        }
    };

    const handleDeleteTest = (pregunta) => {
        const updatedPreguntas = TESTDEACEPTACION.filter((item) => item !== pregunta);
        setTest(updatedPreguntas);
    };

    const clickGoGenerateInform = (event) => {

        const Alldata = {
            fecha_inicio: getCurrentDate(),
            fecha_fin: '',
            tipo_servicio: 'ma',
            estado: 'finalizado',
            idEquipo: selectedSuggestion.idequipo,
            Descripcion: '',
            codigoInforme: selectedSuggestion.idequipo + '-' + cotiz.idcotizacion,
            ubicacion: datos.ubicacion,
            sede: datos.sede,
            numeroActivo: datos.numActivo,
            CodEri: datos.CodEcri,
            CBiomedica: datos.biomedica,
            FrecMantenimiento: datos.Fmante,
            idCotizacion: cotiz.idcotizacion,
            idIngeniero: idInge,
            //cliente: cotiz.nombrecomercial
        }

        event.preventDefault()

        const atributoFiltro1 = TipoPruebasCualitativas
        const filteredCheckboxes1 = Object.entries(checkboxes).filter(([, value]) => value.typeItem === atributoFiltro1);
        const filteredPruebasCual = Object.fromEntries(filteredCheckboxes1);

        const atributoFiltro2 = TipoPruebasCuantitativas
        const filteredCheckboxes2 = Object.entries(checkboxes).filter(([, value]) => value.typeItem === atributoFiltro2);
        const filteredPruebasCuan = Object.fromEntries(filteredCheckboxes2);

        const atributoFiltro3 = TipoMantenimientoPreventivo
        const filteredCheckboxes3 = Object.entries(checkboxes).filter(([, value]) => value.typeItem === atributoFiltro3);
        const filteredMantenPrev = Object.fromEntries(filteredCheckboxes3);

        const atributoFiltro4 = TipoTestAceptacion
        const filteredCheckboxes4 = Object.entries(checkboxes).filter(([, value]) => value.typeItem === atributoFiltro4);
        const filteredtestAceptacion = Object.fromEntries(filteredCheckboxes4);
        //cotiz.idcotizacion
        setDatos(({
            ...datos,
            idEquip: selectedSuggestion.idEquip,
            serialEquip: selectedSuggestion.serialequipo,
        }))
        //console.log(selectedSuggestion)
        //console.log(cotiz)
        //console.log(Alldata)
        if (editing) {
            //console.log(filteredMantenPrev)
            goInformeGenerator(selectedSuggestion, Alldata, filteredPruebasCual, filteredPruebasCuan, filteredMantenPrev, filteredtestAceptacion)
            console.log('generando informe')
        } else {
            goSaveInform(selectedSuggestion, Alldata, filteredPruebasCual, filteredPruebasCuan, filteredMantenPrev, filteredtestAceptacion)
        }
    }

    const clickGoAddEqui = (event) => {
        event.preventDefault()
        goAddEqui();
    }

    const renderCheckboxes = (item) => {
        //checkboxes[item] = 'no apli';
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <input
                    type="checkbox"
                    checked={checkboxes[item].value === 'apto'}
                    onChange={() => handleCheckboxChange(item, 'apto')}
                    style={{ width: '24px', height: '24px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse', marginRight: '5px' }}
                />
                Apto
                <input
                    type="checkbox"
                    checked={checkboxes[item].value === 'no apto'}
                    onChange={() => handleCheckboxChange(item, 'no apto')}
                    style={{ width: '24px', height: '24px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse', marginRight: '5px' }}
                />
                No Apto
                <input
                    type="checkbox"
                    checked={checkboxes[item].value === 'no apli'}
                    onChange={() => handleCheckboxChange(item, 'no apli')}
                    style={{ width: '24px', height: '24px', border: '2px solid #000', borderRadius: '4px', borderCollapse: 'collapse', marginRight: '5px' }}
                />
                No Apli
            </div>
        );
    };

    return (
        <Fragment>
            <div style={{ padding: '10px' }}>
                <h1>Informe de mantenimiento</h1>
                <div className='formulario-doble'>
                    <div className="estadisticas-columna" style={{ backgroundColor: '#FFF', width: '400px', TextAlignment: 'left' }}>
                        <h2>Datos de equipo</h2>
                        {!editing && (<h2>Seleccione su equipo o creelo</h2>)}
                        <table>
                            {!editing && (
                                <tr>
                                    <th>
                                        <Autosuggest
                                            id="serialEquip"
                                            name="serialEquip"
                                            suggestions={suggestionsList}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={suggestion => suggestion.serialequipo}
                                            renderSuggestion={suggestion => <div>
                                                <div>{suggestion.serialequipo} + ( {suggestion.tipoequipo} )</div>
                                            </div>}
                                            onSuggestionSelected={onSuggestionSelected}
                                            inputProps={{
                                                value: suggestionValue,
                                                onChange: handleInputChangeSug,
                                            }}
                                            required
                                        />
                                    </th>
                                    <br />
                                    <td className="celda-datos-enti"><button style={{ marginRight: "10px" }} onClick={clickGoAddEqui} id="search-button" className='btn-icono'>Añadir</button></td>
                                </tr>
                            )}
                            <tr>
                                <th>Serial: </th>
                                <br />
                                <td className="celda-datos-enti">{selectedSuggestion ? selectedSuggestion.serialequipo : "Sin seleccionar"}</td>
                            </tr>
                            <tr>
                                <th>Tipo de equipo: </th>
                                <br />
                                <td className="celda-datos-enti">{selectedSuggestion ? selectedSuggestion.tipoequipo : "Sin seleccionar"}</td>
                            </tr>
                            <p class="help-block text-danger"></p>
                        </table>
                    </div>
                    <div className="estadisticas-columna" style={{ backgroundColor: '#FFF', width: '400px', TextAlignment: 'left' }}>
                        <h2>Datos de entidad</h2>
                        <table>
                            <tr>
                                <th>Codigo: {cotiz.idcotizacion}</th>
                                <br />
                                <td className="celda-datos-enti"></td>
                            </tr>
                            <tr>
                                <th>Entidad:</th>
                                <br />
                                <td className="celda-datos-enti">{cotiz.razonsocial}</td>
                            </tr>
                            <tr>
                                <th id="ciudad" scope="row">Ciudad:</th>
                                <br />
                                <td className="celda-datos-enti">{cotiz.nombre_lugar}</td>
                            </tr>
                            <tr>
                                <th>Dirección:</th>
                                <br />
                                <td className="celda-datos-enti">{cotiz.direccion}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            {selectedSuggestion && (
                <div>
                    <h1>Datos de mantenimiento</h1>
                    <div style={{ width: "90%", fontSize: "20px" }} className='shadow-div'>

                        <form onSubmit={clickGoGenerateInform} className='formulario-doble'>
                            <div className='columna'>
                                <label>Ubicación</label>
                                <input style={{ height: "38px", fontSize: "20px" }} className="form-control"
                                    onChange={handleInputChange} name="ubicacion" id="ubicacion" type="text" value={datos.ubicacion}
                                    placeholder="Ubicacion" required="required" data-validation-required-message="Please enter your User Name." />
                                <p className="help-block text-danger"></p>
                                <label>Sede</label>
                                <input style={{ height: "38px", fontSize: "20px" }} className="form-control"
                                    onChange={handleInputChange} name="sede" id="sede" type="text" value={datos.sede}
                                    required="required" data-validation-required-message="Please enter your User Name." />
                                <p className="help-block text-danger"></p>

                                <label>Num. Activo</label>
                                <input style={{ height: "38px", fontSize: "20px" }} className="form-control"
                                    onChange={handleInputChange} name="numActivo" id="numActivo" type="text" value={datos.numActivo}
                                    placeholder="Num. Activo" required="required" data-validation-required-message="Please enter your User Name." />
                                <p className="help-block text-danger"></p>
                            </div>
                            <div className='columna'>
                                <label>C. Ecri(opcional)</label>
                                <input style={{ height: "38px", fontSize: "20px" }} className="form-control"
                                    onChange={handleInputChange} name="CodEcri" id="CodEcri" type="text" value={datos.CodEcri}
                                    placeholder="" data-validation-required-message="Please enter your User Name." />
                                <p className="help-block text-danger"></p>
                                <label>Es un equipo Biomedico</label>
                                <select name="biomedica" id="biomedica" value={datos.biomedica}
                                    style={{
                                        border: '1px solid #ccc', borderRadius: '5px', padding: '5px',
                                        marginBottom: '10px'
                                    }} onChange={handleSelectChange} >
                                    <option value="0">n/p</option>
                                    <option value="1">Nivel 1</option>
                                    <option value="2">Nivel 2</option>
                                    <option value="3">Nivel 3</option>
                                    <option value="4">Nivel 4</option>
                                    <option value="5">Nivel 5</option>
                                </select>
                                <p className="help-block text-danger"></p>

                                <label>Frecuencia de Mantenimento
                                    <select name="Fmante" value={datos.Fmante} onChange={handleSelectChange} >
                                        <option value="Semanal">Semanal</option>
                                        <option value="Anual">Anual</option>
                                        <option value="Mensual">Mensual</option>
                                    </select>
                                </label>
                            </div>
                            <p className="help-block text-danger"></p>
                            {editing && (
                            <button type="submit" style={{ margin: "5px", width: '50%' }} id="search-button" className='btn-icono'>
                                <h4>Exportar PDF</h4>
                            </button>
                        )}

                        {!editing && (
                            <button type="submit" style={{ margin: "5px", width: '50%' }} id="search-button" className='btn-icono'>
                                <h4>Guardar</h4>
                            </button>
                        )}
                        </form>
                        
                    </div>
                    <div style={{ alignItems: 'center', width:'100%', alignContent: 'center', alignSelf: 'center'}}>
                        <table style={{ borderCollapse: 'collapse', width: '100%' }} className='table-inform'    >
                            {/*CUALITATIVAS*/}
                            <div className='shadow-div' style={{ width: '100%' }}>
                                <h1 style={{width:'100%', textAlign: 'center'}}>PRUEBAS CUALITATIVAS</h1>
                                <thead>
                                    <th colSpan="2">TEST DE INSPECCIÓN Y FUNCIONALIDAD</th>
                                    <th>APTO</th>
                                    <th>NO APTO</th>
                                    <th>NO APLICA</th>
                                </thead>
                                <tbody>
                                    {PRUEBASCUALITATIVAS.map((pregunta, index) => (
                                        <tr key={index}>
                                            <td colSpan="2">{pregunta}</td>
                                            <td colSpan="4">{renderCheckboxes(pregunta)}</td>
                                            <td>
                                                <button onClick={() => handleDeleteCual(pregunta)}
                                                    style={{ padding: '2px 10px', backgroundColor: '#e54040', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)' }}>
                                                    Borrar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <div style={{ margin: '20px 0' }}>
                                    <input
                                        type="text"
                                        value={newPregunta}
                                        onChange={(e) => setNewPregunta(e.target.value)}
                                        placeholder="Nueva pregunta"
                                    />
                                    <button onClick={handleAddCual}
                                        style={{ padding: '2px 10px', backgroundColor: 'green', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px' }}>
                                        Agregar Item
                                    </button>
                                </div>
                            </div>
                            {/*CUANTITATIVAS*/}
                            <div className='shadow-div' style={{ width: '100%' }}>
                                <h1 style={{width:'100%', textAlign: 'center'}}>PRUEBAS CUANTITATIVAS</h1>
                                <thead>
                                    <th colSpan="2"> MEDICIONES REALIZADAS</th>
                                    <th>APTO</th>
                                    <th>NO APTO</th>
                                    <th>NO APLICA</th>
                                </thead>
                                <tbody>
                                    {PRUEBASCUANTITATIVAS.map((pregunta, index) => (
                                        <tr key={index}>
                                            <td colSpan="2">{pregunta}</td>
                                            <td colSpan="4">{renderCheckboxes(pregunta)}</td>
                                            <td>
                                                <button onClick={() => handleDeleteCuan(pregunta)}
                                                    style={{ padding: '2px 10px', backgroundColor: '#e54040', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)' }}>
                                                    Borrar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <div style={{ margin: '20px 0' }}>
                                    <input
                                        type="text"
                                        value={newPregunta}
                                        onChange={(e) => setNewPregunta(e.target.value)}
                                        placeholder="Nueva pregunta"
                                    />
                                    <button onClick={handleAddCuan}
                                        style={{ padding: '2px 10px', backgroundColor: 'green', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px' }}>
                                        Agregar Item
                                    </button>
                                </div>
                            </div>
                            {/*matenimiento prev*/}
                            <div className='shadow-div' style={{ width: '100%' }}>
                                <h1 style={{width:'100%', textAlign: 'center'}}>MANTENIMIENTO PREVENTIVO</h1>
                                <thead>
                                    <th colSpan="2">ACTIVIDADES REALIZADAS</th>
                                    <th>APTO</th>
                                    <th>NO APTO</th>
                                    <th>NO APLICA</th>
                                </thead>
                                <tbody>
                                    {MANTENIMIENTOPREVENTIVO.map((pregunta, index) => (
                                        <tr key={index}>
                                            <td colSpan="2">{pregunta}</td>
                                            <td colSpan="4">{renderCheckboxes(pregunta)}</td>
                                            <td>
                                                <button onClick={() => handleDeleteMante(pregunta)}
                                                    style={{ padding: '2px 10px', backgroundColor: '#e54040', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)' }}>
                                                    Borrar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <div style={{ margin: '20px 0' }}>
                                    <input
                                        type="text"
                                        value={newPregunta}
                                        onChange={(e) => setNewPregunta(e.target.value)}
                                        placeholder="Nueva pregunta"
                                    />
                                    <button onClick={handleAddMante}
                                        style={{ padding: '2px 10px', backgroundColor: 'green', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px' }}>
                                        Agregar Item
                                    </button>
                                </div>
                            </div>
                            {/*Pruebas cuant*/}
                            <div className='shadow-div' style={{ width: '100%' }}>
                                <h1 style={{width:'100%', textAlign: 'center'}}>TEST DE ACEPTACIÓN</h1>
                                <thead>
                                    <th colSpan="2">PRUEBAS FINALES</th>
                                    <th>APTO</th>
                                    <th>NO APTO</th>
                                    <th>NO APLICA</th>
                                </thead>
                                <tbody>
                                    {TESTDEACEPTACION.map((pregunta, index) => (
                                        <tr key={index}>
                                            <td colSpan="2">{pregunta}</td>
                                            <td colSpan="4">{renderCheckboxes(pregunta)}</td>
                                            <td><button onClick={() => handleDeleteTest(pregunta)}
                                                style={{ padding: '2px 10px', backgroundColor: '#e54040', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.2)' }}>
                                                Borrar
                                            </button></td>
                                        </tr>
                                    ))}
                                </tbody>
                                <div style={{ margin: '20px 0' }}>
                                    <input
                                        type="text"
                                        value={newPregunta}
                                        onChange={(e) => setNewPregunta(e.target.value)}
                                        placeholder="Nueva pregunta"
                                    />
                                    <button onClick={handleAddTest}
                                        style={{ padding: '2px 10px', backgroundColor: 'green', color: 'white', borderRadius: '5px', boxShadow: '2px 2px 2px' }}>
                                        Agregar Item
                                    </button>
                                </div>
                            </div>
                        </table>
                    </div>
                    {/* Agrega los otros elementos del formulario debajo de la tabla */}
                </div>
            )}
        </Fragment>
    );
};

export default SaveInform;