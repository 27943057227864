import React, { Fragment } from "react";

const cotizacionesImg = process.env.PUBLIC_URL + '/bases images/billing.png';
const informesImg = process.env.PUBLIC_URL + '/bases images/saveicon.png';
const usuariosImg = process.env.PUBLIC_URL + '/bases images/adduserIcon.png';
const entitiesImg = process.env.PUBLIC_URL + '/bases images/entitie.png';
const equipmentImg = process.env.PUBLIC_URL + '/bases images/equipment.png';

export function Stadistics({ rol, goCotiz, Goaproved, goEqui, goAsig, gousers, goent, goinform }) {

    const clickgouser = (event) => {
        event.preventDefault();
        gousers();
    }

    const clickgocotiz = (event) => {
        event.preventDefault();
        goCotiz()
    }

    const clickgoAproved = (event) => {
        event.preventDefault();
        Goaproved()
    }
    /*
    const clickEnt = (event) => {
        event.preventDefault();
        goent()
    }
    */
    const clickgoEqui = (event) => {
        event.preventDefault();
        goEqui()
    }

    const clickGoasig = (event) => {
        event.preventDefault();
        goAsig()
    }

    const clickGoInform = (event) => {
        event.preventDefault();
        goinform()
    }

    return (
        <Fragment>
            <h1>Bienvenido a MedimainManager</h1>
            <div className='shadow-div' >
                <img style={{ width: '400px', height: '125px' }} className="center-img" src="bases images/LN.png" alt="..." />
                <div className="divider-custom divider-custom-login">
                    <div className="divider-custom-line"></div>
                </div>
                <p>Esta es un sistema generar y asignar cotizaciones a un ingeniero disponible.
                    Este sistema permite llevar una trazabilidad de las cotizaciones y los clientes asociados.</p>
                <div className="divider-custom divider-custom-login">
                    <div className="divider-custom-line"></div>
                </div>
                <div className="estadisticas-container" style={{ backgroundColor: '#FFF' }}>
                    <div className="estadisticas-columna" style={{ backgroundColor: '#EDF9DD' }}>
                        <img
                            src={cotizacionesImg}
                            alt="Cotizaciones Aprobadas"
                            style={{ width: '100px', height: '100px' }} // Ajusta el tamaño aquí
                        />
                        {rol === "Auxiliar admin" && (
                            <button style={{ textAlign: 'center' }} onClick={clickgocotiz} className="btn btn-primary btn-xl center-div-botton">Cotizaciones</button>
                        )}
                        {rol === "Director" && (
                            <button style={{ textAlign: 'center' }} onClick={clickgoAproved} className="btn btn-primary btn-xl center-div-botton">Cotizaciones</button>
                        )}
                        {rol === "Ingeniero" && (
                            <button style={{ textAlign: 'center' }} onClick={clickGoasig} className="btn btn-primary btn-xl center-div-botton">Asingacion</button>
                        )}
                    </div>
                    {(rol === "Ingeniero") && (
                        <div className="estadisticas-columna" style={{ backgroundColor: '#E3ECEE' }}>
                            <img
                                src={equipmentImg}
                                alt="Cotizaciones Aprobadas"
                                style={{ width: '100px', height: '100px' }} // Ajusta el tamaño aquí
                            />
                            <button style={{ textAlign: 'center' }} onClick={clickgoEqui} className="btn btn-primary btn-xl center-div-botton">Equipos</button>
                        </div>
                    )}
                    {(rol === "Auxiliar admin" || rol === "Director") && (
                        <div className="estadisticas-columna" style={{ backgroundColor: '#F9F3DD' }}>
                            <img
                                src={entitiesImg}
                                alt="Informes"
                                style={{ width: '100px', height: '100px' }} // Ajusta el tamaño aquí
                            />
                            <button style={{ textAlign: 'center' }} onClick={clickGoInform} className="btn btn-primary btn-xl center-div-botton">Entidades</button>
                        </div>
                    )}
                    {(rol === "Ingeniero") && (
                        <div className="estadisticas-columna" style={{ backgroundColor: '#E3ECEE' }}>
                            <img
                                src={informesImg}
                                alt="Cotizaciones Aprobadas"
                                style={{ width: '100px', height: '100px' }} // Ajusta el tamaño aquí
                            />
                            <button style={{ textAlign: 'center' }} onClick={clickgoEqui} className="btn btn-primary btn-xl center-div-botton">Listado informes</button>
                        </div>
                    )}
                    {rol === "Director" && (
                        <div className="estadisticas-columna" style={{ backgroundColor: '#F5EEFC' }}>
                            <img
                                src={usuariosImg}
                                alt="Número de Usuarios"
                                style={{ width: '100px', height: '100px' }} // Ajusta el tamaño aquí
                            />
                            <button style={{ textAlign: 'center' }} onClick={clickgouser} className="btn btn-primary btn-xl center-div-botton">Usuarios</button>
                        </div>
                    )}
                </div>

            </div>
            <div className='formulario-doble'>
                <div className="columna shadow-div">
                    <h2>Empezemos</h2>
                    <ul style={{ marginLeft: '50px', padding: '10px' }}>
                        <li style={{ marginBottom: '20px' }}>
                            <span>{1}.Creacion de entidades</span>
                            <button style={{ marginLeft: '20px' }} className="btn btn-primary center-div-botton">Empezar</button>
                        </li>
                        <li>
                            <span>{2}.Creacion de cotizaciones </span>
                            <button className="btn btn-primary center-div-botton">Empezar</button>
                        </li>
                    </ul>
                </div>
                <div className="columna shadow-div">
                    <h2>Video tutorial Creación de cotizaciones</h2>
                    <iframe width="460" height="245" src="https://www.youtube.com/embed/Uv91Dl4Exnc?si=XW_Bt3HsfI-dUEHx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen>

                    </iframe>
                </div>
            </div>
        </Fragment>
    );
};

