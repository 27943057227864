import React, { useState } from "react";

export function MenuLateralAxuliarAdmin({ goStadistics, addEntitie, addCotiz, goTogestionCotiz, goToManegementEntitie}) {
    
    //eventos visuales

    const [showSubmenuCotizaciones, setShowSubmenuContizaciones] = useState(false);
    const [showSubmenuEntidades, setShowSubmenuEntidades] = useState(false);
    const [showSubmenuInformes, setShowSubmenuInformes] = useState(false);

    const toggleSubmenuCotizaciones = () => {
        setShowSubmenuContizaciones(!showSubmenuCotizaciones);
        //setShowSubmenuUsuarios(false)
    };

    const toggleSubmenuEntidades = () => {
        setShowSubmenuEntidades(!showSubmenuEntidades);
        //setShowSubmenuContizaciones(false);
    }

    const toggleSubmenuInformes = () => {
        setShowSubmenuInformes(!showSubmenuInformes);
        //setShowSubmenuContizaciones(false);
    }

    const clickToGoStadistics = (event) => {
        event.preventDefault()
        goStadistics()
    }

    const clickToGoCreateCotiz = (event) => {
        event.preventDefault()
        addCotiz()
    }

    const clickToAddEntitie = (event) => {
        //event.preventDefault()
        addEntitie()
    }

    const clickTogoTogestionCotiz = (event) => {
        event.preventDefault()
        goTogestionCotiz()
    }

    const clickTogoToManagementEntitie = (event) => {
        event.preventDefault()
        goToManegementEntitie()
    }
    //si se borran los formularios se debe borrar href="#"
    return (
        <div className="menu-lateral">
            <ul style={{ marginTop: '80px' }}>
                <li className={`menu-item`}>
                    <a href="#" onClick={clickToGoStadistics}>Inicio</a>
                </li>
                <li className={`menu-item ${showSubmenuCotizaciones ? 'active' : ''}`}>
                    <a onClick={toggleSubmenuCotizaciones}>Cotizaciones</a>
                </li>
                {showSubmenuCotizaciones && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickToGoCreateCotiz}>- Crear cotizaciones</a>
                    </li>
                )}
                {showSubmenuCotizaciones && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickTogoTogestionCotiz}>- Listado de cotizaciones</a>
                    </li>
                )}
                <li className={`menu-item ${showSubmenuEntidades ? 'active' : ''}`}>
                    <a onClick={toggleSubmenuEntidades}>Entidades</a>
                </li>
                {showSubmenuEntidades && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickToAddEntitie}>- Crear entidad</a>
                    </li>
                )}
                {showSubmenuEntidades && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#" onClick={clickTogoToManagementEntitie}>- Listado de Entidades</a>
                    </li>
                )}
                <li className={`menu-item ${showSubmenuInformes ? 'active' : ''}`}>
                    <a onClick={toggleSubmenuInformes}>Informes</a>
                </li>
                {showSubmenuInformes && (
                    <li className={`menu-item`} style={{backgroundColor: '#979797'}}>
                        <a href="#">- Listado de informes</a>
                    </li>
                )}
                <li className={`menu-item`}>
                    <a href="#">Contacto</a>
                </li>
            </ul>
        </div>
    );
}