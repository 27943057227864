import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
export function ManagementEquipment({ goToCreatEquip, urldb }) {

    const [equiposMedicos, setequipos] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderType, setOrderType] = useState({ field: null, ascending: true });

    const fetchEquipos = () => {
        // Realiza una solicitud GET al servidor para obtener cotizaciones actualizadas
        axios.get(urldb + '/equipos')
            .then((response) => {
                setequipos(response.data); // Actualiza el estado de cotizaciones con los datos más recientes
            })
            .catch((error) => {
                console.error('Error al obtener equipos:', error);
            });
    };

    useEffect(() => {
        // Hacer una solicitud GET al servidor Node.js para obtener usuarios
       fetchEquipos()
    }, []);

    useEffect(() => {
        // Cargar datos iniciales (como se mencionó anteriormente)...
        if (orderBy) {
            equiposMedicos.sort((a, b) => {
                const aValue = a[orderBy];
                const bValue = b[orderBy];

                if (orderType.ascending) {
                    if (aValue < bValue) return -1;
                    if (aValue > bValue) return 1;
                    return 0;
                } else {
                    if (aValue > bValue) return -1;
                    if (aValue < bValue) return 1;
                    return 0;
                }
            });
        }
    }, [orderBy, orderType]);

    const clickUserNavCreate = (event) => {
        event.preventDefault()
        goToCreatEquip()
    }

    const handleHeaderClick = (field) => {
        if (field === orderBy) {
            // El usuario hizo clic en el mismo encabezado, alternar entre ascendente y descendente.
            setOrderType((prevOrderType) => ({
                field,
                ascending: !prevOrderType.ascending,
            }));
        } else {
            // El usuario hizo clic en un encabezado diferente, establecer el orden en ascendente.
            setOrderBy(field);
            setOrderType({ field, ascending: true });
        }
    };

    return (
        <Fragment>
            <h1 >Listado de Equipos</h1>
            <section>
            <div className='shadow-div'>
                <div className="search-bar">
                    <input style={{ marginRight: "10px" }} type="text" placeholder="Buscar..." />
                    <button style={{ marginRight: "10px" }} id="search-button" className='btn-icono'>Buscar<img src="bases images/search.png" alt="Agregar" /></button>
                    <select>
                        <option value="marca">marca</option>
                    </select>
                </div>
                <div className="headerMenu">
                    <h1></h1>
                    <button className="btn btn-primary" onClick={clickUserNavCreate}>Crear nuevo equipo</button>
                </div>
                </div>
                <table className="quote-table div-super-container">
                    <thead>
                        <tr>
                            <th onClick={() => handleHeaderClick('serailequipo')}
                                className={orderBy === 'id' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Id/serial
                            </th>
                            
                            <th onClick={() => handleHeaderClick('nombreentidad')}
                                className={orderBy === 'nombreentidad' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Entidad prop.
                            </th>
                            <th onClick={() => handleHeaderClick('marca')}
                                className={orderBy === 'marca' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Marca
                            </th>
                            <th onClick={() => handleHeaderClick('tipoequipo')}
                                className={orderBy === 'tipoequipo' ?
                                    (orderType.ascending ? 'ascending' : 'descending') : ''}>
                                Tipo de Equipo
                            </th>
                            <th>Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {equiposMedicos.map((equipo, index) => (
                            <tr key={index}>
                                <td>{equipo.idequipo} / {equipo.serialequipo}</td>
                                <td>{equipo.nombreentidad}</td>
                                <td>{equipo.marca}</td>
                                <td>{equipo.tipoequipo}</td>
                                <td><button className='btn' value={equipo.idequipo}>Editar</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </Fragment>
    )
}
export default ManagementEquipment